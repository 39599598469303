<template>
    <transition name="modal-fade">
      <div class="modal-backdrop">
        <div class="modal"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <div class="modal__header">
            <button
              type="button"
              class="modal__header-btn-close"
              @click="close"
              aria-label="Close modal"
            >
              x
            </button>
            <div class="modal__header-head"> 
              <p><img :src="image1" class="modal__header-img" /></p>
              <div class="modal__header-name">Мария Белякова</div>
            </div>
            <div class="modal__header-work">
              <h4>Работа со мной — это психологическое консультирование и психологическое
                 сопровождение в интегративном подходе НЛП и экзистенциального анализа по запросам:</h4>
              <div>▪ Эмиграция +  внутреннее столкновение культур (межкультурные конфликты)</div>
              <div>▪ Созависимые отношения</div>
              <div>▪ Сепарация</div>
              <h4>Я проходила обучение:</h4>
              <div>▪ РУДН, Кафедра прикладной психологии и психотерапии</div>
              <div>▪ Психологическая работа с тревогой и депрессией в экзистенциальном анализе, курс Альфрида Лэнгле</div>
              <div>▪ Психологическая помощь при посттравматическом стрессовом расстройстве (ПТСР):
                диагностика и основные подходы к психотерапии взрослых</div>
              <div>Работаю со взрослыми людьми старше 18 лет</div>
              <h4>Индивидуальные сессии со мной длятся 60 минут, стоимость — 7500 rsd</h4>
            </div>
            <div style="display: flex;">
              <form action="https://instagram.com/mariabel.psy" target="_blank" class="modal__form">
                <button class="modal__footer"><img :src="image2" class="modal__footer-img" /></button>
              </form>
              <form action="https://t.me/yellowpagers" target="_blank" class="modal__form">
                <button class="modal__footer"><img :src="image3" class="modal__footer-img" /></button>
              </form>
            </div>
            <p><button
                type="button"
                class="btn-close"
                @click="close"
                aria-label="Close modal"
              >
                x
              </button></p>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  import image1 from "@/img/photo6.jpeg";
  import image2 from "@/img/insticon.png";
  import image3 from "@/img/telicon.png";
  
    export default {
      name: 'pop-up-component6',
      data () {
        return {
          image1: image1,
          image2: image2,
          image3: image3,
        };
      },
      methods: {
        close() {
          this.$emit('close');
        },
      },
    };
  </script>
  
  <style>
    
  </style>
<template>
  <div class="reception">
    <form action="https://t.me/assistant_doctor_o" target="_blank">
      <button class="reception__btn">ЗАПИСАТЬСЯ</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'main-button',
}
</script>

<style scoped>
@media screen and (max-width: 3840px) and (min-width: 2560px) {
  .reception {
    padding: 20px 20px;
    width: 100%;
    font-family: Trebuchet MS;
  }
  .reception__btn {
    width: 30%;
    padding: 15px 7px;
    margin: 20px 10px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: bold;
    background-color: black;
    color: white;
    cursor: pointer;
  }
  .reception__btn:hover {
    background-color: white;
    color: black;
  }
}
@media screen and (max-width: 2560px) and (min-width: 1920px) {
  .reception {
    padding: 20px 20px;
    width: 100%;
    font-family: Trebuchet MS;
  }
  .reception__btn {
    width: 30%;
    padding: 15px 7px;
    margin: 20px 10px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: bold;
    background-color: black;
    color: white;
    cursor: pointer;
  }
  .reception__btn:hover {
    background-color: white;
    color: black;
  }
}
@media screen and (max-width: 1920px) and (min-width: 1440px) {
  .reception {
    padding: 20px 20px;
    width: 100%;
    font-family: Trebuchet MS;
  }
  .reception__btn {
    width: 30%;
    padding: 15px 7px;
    margin: 20px 10px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: bold;
    background-color: black;
    color: white;
    cursor: pointer;
  }
  .reception__btn:hover {
    background-color: white;
    color: black;
  }
}

@media screen and (max-width: 1440px) and (min-width: 960px) {
  .reception {
    padding: 20px 20px;
    width: 100%;
    font-family: Trebuchet MS;
  }
  .reception__btn {
    width: 30%;
    padding: 15px 7px;
    margin: 20px 10px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: bold;
    background-color: black;
    color: white;
    cursor: pointer;
  }
  .reception__btn:hover {
    background-color: white;
    color: black;
  }
}
@media screen and (max-width: 960px) and (min-width: 640px) {
  .reception {
    padding: 20px 20px;
    width: 100%;
    font-family: Trebuchet MS;
  }
  .reception__btn {
    width: 40%;
    padding: 15px 7px;
    margin: 20px 10px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: bold;
    background-color: black;
    color: white;
    cursor: pointer;
  }
  .reception__btn:hover {
    background-color: white;
    color: black;
  }
}
@media screen and (max-width: 640px) and (min-width: 480px) {
  .reception {
    padding: 20px 20px;
    width: 100%;
    font-family: Trebuchet MS;
  }
  .reception__btn {
    width: 50%;
    padding: 15px 7px;
    margin: 20px 10px;
    border-radius: 30px;
    font-size: 15px;
    font-weight: bold;
    background-color: black;
    color: white;
    cursor: pointer;
  }
  .reception__btn:hover {
    background-color: white;
    color: black;
  }
}
@media screen and (max-width: 480px) and (min-width: 320px) {
  .reception {
    padding: 20px 20px;
    width: 100%;
    font-family: Trebuchet MS;
  }
  .reception__btn {
    width: 80%;
    padding: 15px 7px;
    margin: 20px 10px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: bold;
    background-color: black;
    color: white;
    cursor: pointer;
  }
  .reception__btn:hover {
    background-color: white;
    color: black;
  }
}

</style>
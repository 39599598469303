<template>
  <div class="main-page">
    <UpComponent />
    <SliderComponent />
    <CommunityCopmonent />
  </div>
</template>

<script>
import UpComponent from "@/components/UpComponent.vue";
import SliderComponent from "@/components/SliderComponent.vue";
import CommunityCopmonent from "@/components/CommunityCopmonent.vue";

export default {
  name: 'main-page',
  components: {
    UpComponent,
    SliderComponent,
    CommunityCopmonent,
  }
}
</script>

<style scoped>

</style>
<template>
    <div>
        <div class="up">
            <div class="up__info">
                <div>
                    <div class="up__info-head"><strong>Mental Care Crew</strong><br>команда психологов и психотерапевтов</div>
                    <h2>помогаем справиться</h2>
                    <div class="up__info-idea">Мы понимаем, слушаем, направляем, находим индивидуальный
                        подход, чтобы вы получали от жизни удовольствие</div>
                </div>
                <div class="up__image">
                    <img :src="image1" class="up__image-img"/>
                </div>  
            </div>
            <div class="up__info-btns">
                <UpButton />
                <UpButton2 />
            </div>
        </div>
        <div class="down">
            <div class="down__block">
                <img :src="image2" />
                <div class="down__block-text">ЛГБТК+ Френдли</div>
            </div>
            <div class="down__block">
                <img :src="image3" />
                <div class="down__block-text">Научный подход</div>
            </div>
            <div class="down__block">
                <img :src="image4" />
                <div class="down__block-text">Доказательная медицина</div>
            </div>
            <div class="down__block">
                <img :src="image5" />
                <div class="down__block-text">Онлайн или очно в Сербии</div>
            </div>
        </div>    
    </div>
</template>

<script>
import UpButton from "@/components/UI/UpButton";
import UpButton2 from "@/components/UI/UpButton2";
import image1 from "@/img/Uppic.jpg";
import image2 from "@/img/lgbt.png";
import image3 from "@/img/micro.png";
import image4 from "@/img/pilula.png";
import image5 from "@/img/comp.png";

    export default {
        name: 'up-component',
        components: {
            UpButton,
            UpButton2,
        },
        data () {
            return {
                image1: image1,
                image2: image2,
                image3: image3,
                image4: image4,
                image5: image5,
            }
        }
    }
</script>

<style scoped>
@media screen and (max-width: 3840px) and (min-width: 2560px) {
    .up {
        font-family: Trebuchet MS;
        display: flex;
        flex-direction: column;
    }
    .up__info {
        display: flex;
        margin: 15px 20px;
        color: black;
        text-align: left;
        background: #FBFBFB;
    }
    .up__info-head {
        font-size: 35px;
        padding: 25px 15px;
    }
    .up__info-idea {
        font-size: 25px;
        padding: 15px 15px;
    }
    h2 {
        font-size: 30px;
        padding: 15px 15px;
    }
    .up__info-btns {
        width: 50%;
        position: absolute;
        bottom: 100px;
        left: 15px;
        display: flex;  
    }
    .up__image {
        width: 100%;
    }
    .up__image-img {
        height: 450px;
    }
    .down {
        display: flex;
        justify-content: space-around;
        margin: 15px 5px;
    }
    .down__block {
        display: flex;
        justify-content: flex-start;
    }
    .down__block-text {
        font-family: Trebuchet MS;
        font-size: 17px;
    }
}
@media screen and (max-width: 2560px) and (min-width: 1920px) {
    .up {
        font-family: Trebuchet MS;
        display: flex;
        flex-direction: column;
    }
    .up__info {
        display: flex;
        margin: 15px 20px;
        color: black;
        text-align: left;
        background: #FBFBFB;
    }
    .up__info-head {
        font-size: 35px;
        padding: 25px 15px;
    }
    .up__info-idea {
        font-size: 25px;
        padding: 15px 15px;
    }
    h2 {
        font-size: 30px;
        padding: 15px 15px;
    }
    .up__info-btns {
        width: 50%;
        position: absolute;
        bottom: 100px;
        left: 15px;
        display: flex;  
    }
    .up__image {
        width: 100%;
    }
    .up__image-img {
        height: 450px;
    }
    .down {
        display: flex;
        justify-content: space-around;
        margin: 15px 5px;
    }
    .down__block {
        display: flex;
        justify-content: flex-start;
    }
    .down__block-text {
        font-family: Trebuchet MS;
        font-size: 17px;
    }
}
@media screen and (max-width: 1920px) and (min-width: 1600px) {
    .up {
        font-family: Trebuchet MS;
        display: flex;
        flex-direction: column;
    }
    .up__info {
        display: flex;
        margin: 15px 20px;
        color: black;
        text-align: left;
        background: #FBFBFB;
    }
    .up__info-head {
        font-size: 55px;
        padding: 25px 15px;
    }
    .up__info-idea {
        font-size: 40px;
        padding: 15px 15px;
    }
    h2 {
        font-size: 45px;
        padding: 15px 15px;
    }
    .up__info-btns {
        width: 50%;
        position: relative;
        bottom: 100px;
        display: flex;  
    }
    .up__image {
        width: 100%;
    }
    .up__image-img {
        height: 700px;
        margin-left: 150px;
    }
    .down {
        display: flex;
        justify-content: space-around;
        margin: 15px 5px;
    }
    .down__block {
        display: flex;
        justify-content: flex-start;
        position: relative;
        bottom: 50px;
    }
    .down__block-text {
        font-family: Trebuchet MS;
        font-size: 30px;
    }
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
    .up {
        font-family: Trebuchet MS;
        display: flex;
        flex-direction: column;
    }
    .up__info {
        display: flex;
        margin: 15px 20px;
        color: black;
        text-align: left;
        background: #FBFBFB;
    }
    .up__info-head {
        font-size: 55px;
        padding: 25px 15px;
    }
    .up__info-idea {
        font-size: 35px;
        padding: 15px 15px;
    }
    h2 {
        font-size: 40px;
        padding: 15px 15px;
    }
    .up__info-btns {
        width: 50%;
        position: relative;
        bottom: 80px;
        display: flex;  
    }
    .up__image {
        width: 100%;
    }
    .up__image-img {
        height: 600px;
        margin-left: 150px;
    }
    .down {
        display: flex;
        justify-content: space-around;
        margin: 15px 5px;
    }
    .down__block {
        display: flex;
        justify-content: flex-start;
        position: relative;
        bottom: 50px;
    }
    .down__block-text {
        font-family: Trebuchet MS;
        font-size: 25px;
    }
}
@media screen and (max-width: 1440px) and (min-width: 1280px) {
    .up {
        font-family: Trebuchet MS;
        display: flex;
        flex-direction: column;
    }
    .up__info {
        display: flex;
        margin: 15px 20px;
        color: black;
        text-align: left;
        background: #FBFBFB;
    }
    .up__info-head {
        font-size: 35px;
        padding: 25px 15px;
    }
    .up__info-idea {
        font-size: 25px;
        padding: 15px 15px;
    }
    h2 {
        font-size: 30px;
        padding: 15px 15px;
    }
    .up__info-btns {
        width: 50%;
        position: relative;
        bottom: 150px;
        display: flex;  
    }
    .up__image {
        width: 100%;
    }
    .up__image-img {
        height: 500px;
    }
    .down {
        display: flex;
        justify-content: space-around;
        margin: 5px 5px;
    }
    .down__block {
        display: flex;
        justify-content: flex-start;
        position: relative;
        bottom: 70px;
    }
    .down__block-text {
        font-family: Trebuchet MS;
        font-size: 20px;
    }
}
@media screen and (max-width: 1280px) and (min-width: 960px) {
    .up {
        font-family: Trebuchet MS;
        display: flex;
        flex-direction: column;
    }
    .up__info {
        display: flex;
        margin: 15px 10px;
        color: black;
        text-align: left;
        background: #FBFBFB;
    }
    .up__info-head {
        font-size: 35px;
        padding: 25px 15px;
    }
    .up__info-idea {
        font-size: 25px;
        padding: 5px 15px;
    }
    h2 {
        font-size: 30px;
        padding: 5px 15px;
    }
    .up__info-btns {
        width: 50%;
        position: relative;
        bottom: 20px;
        display: flex; 
    }
    .up__image {
        width: 100%;
    }
    .up__image-img {
        height: 380px;
    }
    .down {
        display: flex;
        justify-content: space-around;
        margin: 15px 5px;
    }
    .down__block {
        display: flex;
        justify-content: flex-start;
    }
    .down__block-text {
        font-family: Trebuchet MS;
        font-size: 18px;
    }
}
@media screen and (max-width: 960px) and (min-width: 840px) {
    .up {
        font-family: Trebuchet MS;
        display: flex;
        flex-direction: column;
    }
    .up__info {
        display: flex;
        margin: 15px 10px;
        color: black;
        text-align: left;
        background: #FBFBFB;
    }
    .up__info-head {
        font-size: 35px;
        padding: 25px 15px;
    }
    .up__info-idea {
        font-size: 23px;
        padding: 5px 15px;
    }
    h2 {
        font-size: 30px;
        padding: 5px 15px;
    }
    .up__info-btns {
        width: 50%;
        display: flex; 
    }
    .up__image {
        width: 100%;
    }
    .up__image-img {
        height: 350px;
    }
    .down {
        display: flex;
        justify-content: space-around;
        margin: 15px 5px;
    }
    .down__block {
        display: flex;
        justify-content: flex-start;
        margin: 15px 5px;
    }
    .down__block-text {
        font-family: Trebuchet MS;
        font-size: 15px;
    }
}
@media screen and (max-width: 840px) and (min-width: 640px) {
    .up {
        font-family: Trebuchet MS;
        display: flex;
        flex-direction: column;
    }
    .up__info {
        display: flex;
        margin: 15px 10px;
        color: black;
        text-align: left;
        background: #FBFBFB;
    }
    .up__info-head {
        font-size: 25px;
        padding: 25px 15px;
    }
    .up__info-idea {
        font-size: 22px;
        padding: 5px 15px;
    }
    h2 {
        font-size: 22px;
        padding: 5px 15px;
    }
    .up__info-btns {
        width: 50%;
        display: flex; 
    }
    .up__image {
        width: 100%;
    }
    .up__image-img {
        height: 300px;
    }
    .down {
        display: flex;
        justify-content: space-around;
        margin: 15px 5px;
    }
    .down__block {
        display: flex;
        justify-content: flex-start;
    }
    .down__block-text {
        font-family: Trebuchet MS;
        font-size: 14px;
    }
}
@media screen and (max-width: 640px) and (min-width: 480px) {
    .up {
        font-family: Trebuchet MS;
        display: flex;
        flex-direction: column;
    }
    .up__info {
        display: flex;
        flex-direction: column;
        margin: 15px 10px;
        color: black;
        text-align: center;
        background: #FBFBFB;
    }
    .up__info-head {
        font-size: 27px;
        padding: 25px 15px;
    }
    .up__info-idea {
        font-size: 20px;
        padding: 5px 15px;
    }
    h2 {
        font-size: 23px;
        padding: 5px 15px;
    }
    .up__info-btns {
        width: 100%;
        display: flex; 
    }
    .up__image {
        width: 100%;
    }
    .up__image-img {
        height: 300px;
    }
    .down {
        position: relative;
        bottom: 0;
        left: 25%;
        display: flex;
        flex-direction: column;
        margin: 7px 5px;
    }
    .down__block {
        display: flex;
        margin: 5px 5px;
    }
    .down__block-text {
        font-family: Trebuchet MS;
        font-size: 16px;
    }
}
@media screen and (max-width: 480px) and (min-width: 320px) {
    .up {
        font-family: Trebuchet MS;
        display: flex;
        flex-direction: column;
    }
    .up__info {
        display: flex;
        flex-direction: column;
        margin: 10px 10px;
        color: black;
        text-align: center;
        background: #FBFBFB;
    }
    .up__info-head {
        font-size: 27px;
        padding: 5px 15px;
    }
    .up__info-idea {
        font-size: 20px;
        padding: 5px 15px;
    }
    h2 {
        font-size: 23px;
        padding: 5px 15px;
    }
    .up__info-btns {
        width: 100%;
        display: flex; 
        flex-direction: column;
    }
    .up__image {
        width: 100%;
    }
    .up__image-img {
        height: 280px;
    }
    .down {
        position: relative;
        bottom: 0;
        left: 10%;
        display: flex;
        flex-direction: column;
        margin: 7px 5px;
    }
    .down__block {
        display: flex;
        margin: 5px 5px;
    }
    .down__block-text {
        font-family: Trebuchet MS;
        font-size: 16px;
    }
}
</style>
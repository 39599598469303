<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <div class="modal__header">
          <button
              type="button"
              class="modal__header-btn-close"
              @click="close"
              aria-label="Close modal"
            >
              x
            </button>
          <div class="modal__header-head"> 
            <p><img :src="image1" class="modal__header-img" /></p>
            <div class="modal__header-name">Маша Бурыка</div>
          </div>
          <div class="modal__header-work">
            <h4>Основные запросы, на которых я специализируюсь:</h4>
            <div>▪ Психотерапия взрослых, клиент-центрированный подход</div>
            <div>▪ Психотерапия детей, детская психодрама (групповая и индивидуальная форма)</div>
            <div>▪ Когнитивно-поведенческая терапия поведенческих сложностей у детей и подростков</div>
            <div>▪ Психодиагностика детей и подростков</div>
            <h4>Я проходила обучение:</h4>
            <div>▪ Санкт-Петербургский Государственный Университет, факультет психологии,
                 клиническая психология</div>
            <div>▪ Международная школа психотерапии, консультирования и ведения групп «Гармония»</div>
            <div>▪ Институт психотерапии и медицинской психологии имени Карвасарского</div>
            <div>Работаю со взрослыми и детьми старше 5 лет</div>
            <h4>Сессии со мной длятся 60 минут, стоимость — 7500 rsd</h4>
          </div>
          <form action="https://t.me/odetiahprosto" target="_blank" class="modal__form">
            <button class="modal__footer"><img :src="image2" class="modal__footer-img" /></button>
          </form>
          <p><button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
            >
              x
            </button></p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import image1 from "@/img/photo1.jpeg";
import image2 from "@/img/telicon.png";

  export default {
    name: 'pop-up-component1',
    data () {
      return {
        image1: image1,
        image2: image2,
      };
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style>
@media screen and (max-width: 1920px) and (min-width: 1600px) {
  .modal-backdrop {
    width: 60%;
    position: fixed;
    top: 10px;
    bottom: 10px;
    left: 250px;
    border: 1px solid black;
    border-radius: 30px;
    overflow: auto;
    text-align: center;
    opacity: 1;
    z-index: 2;
  }
  .modal {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
  }
  .modal__header {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .modal__header-btn-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 10%;
    font-size: 50px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
    border: none;
  }
  .modal__header-head {
    display: flex;
    flex-direction: column;
    line-height: 2;
  }
  .modal__header-img {
    width: 70%;
    border-radius: 20px;
  }
  .modal__header-name {
    font-size: 55px;
  }
  .modal__header-work {
    text-align: left;
    line-height: 2;
    font-size: 35px;
  }
  .modal__form {
    text-align: left;
  }
  .modal__footer {
    border: none;
    cursor: pointer;
    width: 100%;
    background-color: #FFFFFF;
  }
  .modal__footer-img {
    width: 80px;
  }
  .btn-close {
    width: 60%;
    font-size: 50px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
    border-radius: 30px;
  }
  .btn-close:hover {
    background-color: black;
    color: white;
  }
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
  .modal-backdrop {
    width: 60%;
    position: fixed;
    top: 10px;
    bottom: 10px;
    left: 250px;
    border: 1px solid black;
    border-radius: 30px;
    overflow: auto;
    text-align: center;
    opacity: 1;
    z-index: 2;
  }
  .modal {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
  }
  .modal__header {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .modal__header-btn-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 10%;
    font-size: 50px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
    border: none;
  }
  .modal__header-head {
    display: flex;
    flex-direction: column;
    line-height: 2;
  }
  .modal__header-img {
    width: 70%;
    border-radius: 20px;
  }
  .modal__header-name {
    font-size: 50px;
  }
  .modal__header-work {
    text-align: left;
    line-height: 2;
    font-size: 30px;
  }
  .modal__form {
    text-align: left;
  }
  .modal__footer {
    border: none;
    cursor: pointer;
    width: 100%;
    background-color: #FFFFFF;
  }
  .modal__footer-img {
    width: 80px;
  }
  .btn-close {
    width: 60%;
    font-size: 50px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
    border-radius: 30px;
  }
  .btn-close:hover {
    background-color: black;
    color: white;
  }
}
@media screen and (max-width: 1440px) and (min-width: 960px) {
  .modal-backdrop {
    width: 60%;
    position: fixed;
    top: 10px;
    bottom: 10px;
    left: 250px;
    border: 1px solid black;
    border-radius: 30px;
    overflow: auto;
    text-align: center;
    opacity: 1;
    z-index: 2;
  }
  .modal {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
  }
  .modal__header {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .modal__header-btn-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 10%;
    font-size: 50px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
    border: none;
  }
  .modal__header-head {
    display: flex;
    flex-direction: column;
    line-height: 2;
  }
  .modal__header-img {
    width: 70%;
    border-radius: 20px;
  }
  .modal__header-name {
    font-size: 50px;
  }
  .modal__header-work {
    text-align: left;
    line-height: 2;
    font-size: 20px;
  }
  .modal__form {
    text-align: left;
  }
  .modal__footer {
    border: none;
    cursor: pointer;
    width: 100%;
    background-color: #FFFFFF;
  }
  .modal__footer-img {
    width: 50px;
  }
  .btn-close {
    width: 60%;
    font-size: 50px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
    border-radius: 30px;
  }
  .btn-close:hover {
    background-color: black;
    color: white;
  }
}
@media screen and (max-width: 960px) and (min-width: 740px) {
  .modal-backdrop {
    width: 50%;
    position: fixed;
    top: 10px;
    bottom: 10px;
    left: 25%;
    right: 10px;
    border: 1px solid black;
    border-radius: 30px;
    overflow: auto;
    text-align: center;
    opacity: 1;
    z-index: 2;
  }
  .modal {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
  }
  .modal__header {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .modal__header-btn-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 10%;
    font-size: 30px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
    border: none;
  }
  .modal__header-head {
    display: flex;
    flex-direction: column;
    line-height: 2;
  }
  .modal__header-img {
    width: 60%;
    border-radius: 20px;
  }
  .modal__header-name {
    font-size: 30px;
  }
  .modal__header-work {
    text-align: left;
    line-height: 2;
    font-size: 15px;
  }
  .modal__form {
    text-align: left;
  }
  .modal__footer {
    border: none;
    cursor: pointer;
    width: 100%;
    background-color: #FFFFFF;
  }
  .modal__footer-img {
    width: 40px;
  }
  .btn-close {
    width: 40%;
    font-size: 30px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
    border-radius: 30px;
  }
  .btn-close:hover {
    background-color: black;
    color: white;
  }
}
@media screen and (max-width: 740px) and (min-width: 600px) { 
  .modal-backdrop {
    width: 50%;
    position: fixed;
    top: 10px;
    bottom: 10px;
    left: 25%;
    right: 10px;
    border: 1px solid black;
    border-radius: 30px;
    overflow: auto;
    text-align: center;
    opacity: 1;
    z-index: 2;
  }
  .modal {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
  }
  .modal__header {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .modal__header-btn-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 10%;
    font-size: 30px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
    border: none;
  }
  .modal__header-head {
    display: flex;
    flex-direction: column;
    line-height: 2;
  }
  .modal__header-img {
    width: 80%;
    border-radius: 20px;
  }
  .modal__header-name {
    font-size: 20px;
  }
  .modal__header-work {
    text-align: left;
    line-height: 2;
    font-size: 13px;
  }
  .modal__form {
    text-align: left;
  }
  .modal__footer {
    border: none;
    cursor: pointer;
    width: 100%;
    background-color: #FFFFFF;
  }
  .modal__footer-img {
    width: 35px;
  }
  .btn-close {
    width: 60%;
    font-size: 30px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
    border-radius: 30px;
  }
  .btn-close:hover {
    background-color: black;
    color: white;
  }
}
@media screen and (max-width: 600px) and (min-width: 480px) {
  .modal-backdrop {
    width: 80%;
    position: fixed;
    top: 10px;
    bottom: 10px;
    left: 12%;
    right: 10px;
    border: 1px solid black;
    border-radius: 30px;
    overflow: auto;
    text-align: center;
    opacity: 1;
    z-index: 2;
  }
  .modal {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
  }
  .modal__header {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .modal__header-btn-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 10%;
    font-size: 30px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
    border: none;
  }
  .modal__header-head {
    display: flex;
    flex-direction: column;
    line-height: 2;
  }
  .modal__header-img {
    width: 80%;
    border-radius: 20px;
  }
  .modal__header-name {
    font-size: 20px;
  }
  .modal__header-work {
    text-align: left;
    line-height: 2;
    font-size: 13px;
  }
  .modal__form {
    text-align: left;
  }
  .modal__footer {
    border: none;
    cursor: pointer;
    width: 100%;
    background-color: #FFFFFF;
  }
  .modal__footer-img {
    width: 35px;
  }
  .btn-close {
    width: 60%;
    font-size: 30px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
    border-radius: 30px;
  }
  .btn-close:hover {
    background-color: black;
    color: white;
  }
}
@media screen and (max-width: 480px) and (min-width: 320px) {
  .modal-backdrop {
    width: 80%;
    position: fixed;
    top: 10px;
    bottom: 10px;
    left: 12%;
    right: 10px;
    border: 1px solid black;
    border-radius: 30px;
    overflow: auto;
    text-align: center;
    opacity: 1;
    z-index: 2;
  }
  .modal {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
  }
  .modal__header {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .modal__header-btn-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 10%;
    font-size: 30px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
    border: none;
  }
  .modal__header-head {
    display: flex;
    flex-direction: column;
    line-height: 2;
  }
  .modal__header-img {
    width: 80%;
    border-radius: 20px;
  }
  .modal__header-name {
    font-size: 20px;
  }
  .modal__header-work {
    text-align: left;
    line-height: 2;
    font-size: 15px;
  }
  .modal__form {
    text-align: left;
  }
  .modal__footer {
    border: none;
    cursor: pointer;
    width: 100%;
    background-color: #FFFFFF;
  }
  .modal__footer-img {
    width: 35px;
    
  }
  .btn-close {
    width: 60%;
    font-size: 30px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
    border-radius: 30px;
  }
  .btn-close:hover {
    background-color: black;
    color: white;
  }
}
</style>
import MainPage from "@/pages/MainPage";
import {createRouter, createWebHistory} from "vue-router";
import PoliticPage from "@/pages/PoliticPage";
import SliderComponent from "@/components/SliderComponent.vue";
import UpComponent from "@/components/UpComponent.vue";
import FormComponent from "@/components/FormComponent.vue";

const routes = [
    {
        path: '/',
        component: MainPage
    },
    {
        path: '/politic',
        component: PoliticPage
    },
    {
        path: '/team',
        component: SliderComponent
    },
    {
        path: '/we',
        component: UpComponent
    },
    {
        path: '/form',
        component: FormComponent
    },
]

const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL)
})

export default router;
<template>
    <div class="up-button">
        <form action="https://t.me/MCC_assistant" target="_blank">
            <button class="up-button__btn">записаться</button>
        </form>
    </div>
</template>

<script>
    export default {
        name: 'up-button',
    }
</script>

<style scoped>
@media screen and (max-width: 1920px) and (min-width: 1600px) {
  .up-button {
    padding: 10px 5px;
    width: 100%;
    font-family: Trebuchet MS;
  }
  .up-button__btn {
    width: 80%;
    padding: 15px 7px;
    margin: 10px 5px;
    border-radius: 30px;
    font-size: 35px;
    font-weight: bold;
    background-color: #5E9C51;
    border: 1px solid #5E9C51;
    color: #FFEBCD;
    cursor: pointer;
  }
  .up-button__btn:hover {
    background-color: white;
    color: #658F50;
  }
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
  .up-button {
    padding: 10px 5px;
    width: 100%;
    font-family: Trebuchet MS;
  }
  .up-button__btn {
    width: 70%;
    padding: 15px 7px;
    margin: 10px 5px;
    border-radius: 30px;
    font-size: 30px;
    font-weight: bold;
    background-color: #5E9C51;
    border: 1px solid #5E9C51;
    color: #FFEBCD;
    cursor: pointer;
  }
  .up-button__btn:hover {
    background-color: white;
    color: #658F50;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1280px) {
  .up-button {
    padding: 10px 5px;
    width: 100%;
    font-family: Trebuchet MS;
  }
  .up-button__btn {
    width: 70%;
    padding: 15px 7px;
    margin: 10px 5px;
    border-radius: 30px;
    font-size: 25px;
    font-weight: bold;
    background-color: #5E9C51;
    border: 1px solid #5E9C51;
    color: #FFEBCD;
    cursor: pointer;
  }
  .up-button__btn:hover {
    background-color: white;
    color: #658F50;
  }
}
@media screen and (max-width: 1280px) and (min-width: 960px) {
  .up-button {
    padding: 10px 5px;
    width: 100%;
    font-family: Trebuchet MS;
  }
  .up-button__btn {
    width: 60%;
    padding: 10px 7px;
    margin: 10px 5px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: bold;
    background-color: #5E9C51;
    border: 1px solid #5E9C51;
    color: #FFEBCD;
    cursor: pointer;
  }
  .up-button__btn:hover {
    background-color: white;
    color: #658F50;
  }
}
@media screen and (max-width: 960px) and (min-width: 840px) {
  .up-button {
    padding: 10px 5px;
    width: 100%;
    font-family: Trebuchet MS;
  }
  .up-button__btn {
    width: 80%;
    height: 50px;
    padding: 10px 7px;
    margin: 10px 5px;
    border-radius: 30px;
    font-size: 16px;
    font-weight: bold;
    background-color: #5E9C51;
    border: 1px solid #5E9C51;
    color: #FFEBCD;
    cursor: pointer;
  }
  .up-button__btn:hover {
    background-color: white;
    color: #658F50;
  }
}
@media screen and (max-width: 840px) and (min-width: 640px) {
  .up-button {
    padding: 20px 20px;
    width: 100%;
    font-family: Trebuchet MS;
  }
  .up-button__btn {
    width: 100%;
    height: 50px;
    padding: 5px 7px;
    margin: 10px 10px;
    border-radius: 30px;
    font-size: 16px;
    font-weight: bold;
    background-color: #5E9C51;
    border: 1px solid #5E9C51;
    color: #FFEBCD;
    cursor: pointer;
  }
  .up-button__btn:hover {
    background-color: white;
    color: #658F50;
  }
}
@media screen and (max-width: 640px) and (min-width: 480px) {
  .up-button {
    padding: 20px 20px;
    width: 100%;
    font-family: Trebuchet MS;
  }
  .up-button__btn {
    width: 100%;
    padding: 15px 7px;
    margin: 20px 10px;
    border-radius: 30px;
    font-size: 17px;
    font-weight: bold;
    background-color: #5E9C51;
    border: 1px solid #5E9C51;
    color: #FFEBCD;
    cursor: pointer;
  }
  .up-button__btn:hover {
    background-color: white;
    color: #658F50;
  }
}
@media screen and (max-width: 480px) and (min-width: 320px) {
  .up-button {
    padding: 10px 20px;
    width: 100%;
    font-family: Trebuchet MS;
  }
  .up-button__btn {
    width: 80%;
    padding: 15px 7px;
    margin: 5px 10px;
    border-radius: 30px;
    font-size: 16px;
    font-weight: bold;
    background-color: #5E9C51;
    border: 1px solid #5E9C51;
    color: #FFEBCD;
    cursor: pointer;
  }
  .up-button__btn:hover {
    background-color: white;
    color: #658F50;
  }
}
</style>
<template>
    <div class="politic">
        <button @click="$router.push('/')" class="politic__btn">X</button>
        <div class="politic__page">
            <h1>Политика конфиденциальности персональных данных</h1>
            <div>Настоящая Политика конфиденциальности персональных данных 
                (далее – Политика конфиденциальности) действует в отношении всей 
                информации, которую сайт Mental Care Crew, (далее – MCC) расположенный
                на доменном имени mentalcarecrew.co (а также его субдоменах), может
                получить о Пользователе во время использования сайта mentalcarecrew.co 
                (а также его субдоменов), его программ и его продуктов.</div>
            <h2>1. Определение терминов</h2>
            <strong><div>1.1 В настоящей Политике конфиденциальности используются следующие термины:</div></strong>
            <div>1.1.1. «Администрация сайта» (далее – Администрация) – уполномоченные
                сотрудники на управление сайтом Mental Care Crew, которые организуют
                и (или) осуществляют обработку персональных данных, а также определяет
                цели обработки персональных данных, состав персональных данных,
                подлежащих обработке, действия (операции), совершаемые с персональными данными.</div>
            <div>1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или 
                косвенно определенному, или определяемому физическому лицу (субъекту 
                персональных данных).</div>
            <div>1.1.3. «Обработка персональных данных» - любое действие (операция) или 
                совокупность действий (операций), совершаемых с использованием средств
                автоматизации или без использования таких средств с персональными данными,
                включая сбор, запись, систематизацию, накопление, хранение, уточнение 
                (обновление, изменение), извлечение, использование, передачу (распространение, 
                предоставление, доступ), обезличивание, блокирование, удаление, уничтожение
                персональных данных.</div>
            <div>1.1.4. «Конфиденциальность персональных данных» - обязательное
                для соблюдения Оператором или иным получившим доступ к персональным 
                данным лицом требование не допускать их распространения без согласия 
                субъекта персональных данных или наличия иного законного основания.</div>
            <div>1.1.5. «Сайт Mental Care Crew» - это совокупность связанных между
                собой веб-страниц, размещенных в сети Интернет по уникальному адресу
                (URL): mentalcarecrew.co, а также его субдоменах.</div>
            <div>1.1.6. «Субдомены» - это страницы или совокупность страниц, расположенные
                на доменах третьего уровня, принадлежащие сайту Mental Care Crew, а
                также другие временные страницы, внизу который указана контактная
                информация Администрации</div>
            <div>1.1.7. «Пользователь сайта Mental Care Crew » (далее Пользователь) – лицо,
                имеющее доступ к сайту Mental Care Crew, посредством сети Интернет и 
                использующее информацию, материалы и продукты сайта Mental Care Crew.</div>
            <div>1.1.8. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и 
                хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый
                раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу
                соответствующего сайта.</div>
            <div>1.1.9. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети,
                через который Пользователь получает доступ на MCC</div>
            <h2>2. Общие положения</h2>    
            <div>2.1. Использование сайта Mental Care Crew Пользователем означает согласие 
                с настоящей Политикой конфиденциальности и условиями обработки персональных 
                данных Пользователя.</div>
            <div>2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь 
                должен прекратить использование сайта Mental Care Crew.</div>
            <div>2.3. Настоящая Политика конфиденциальности применяется к сайту Mental Care Crew.
                MCC не контролирует и не несет ответственность за сайты третьих лиц, на которые
                Пользователь может перейти по ссылкам, доступным на сайте Mental Care Crew.</div>
            <div>2.4. Администрация не проверяет достоверность персональных данных, предоставляемых 
                Пользователем.</div>
            <h2>3. Предмет политики конфиденциальности</h2>
            <div>3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации
                по неразглашению и обеспечению режима защиты конфиденциальности персональных
                данных, которые Пользователь предоставляет по запросу Администрации при регистрации
                на сайте Mental Care Crew или при подписке на информационную e-mail рассылку.</div>
            <div>3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики
                конфиденциальности, предоставляются Пользователем путём заполнения форм на сайте
                Mental Care Crew и включают в себя следующую информацию:</div>
            <div>3.2.1. фамилию, имя, отчество Пользователя;</div>
            <div>3.2.2. контактный телефон Пользователя;</div>
            <div>3.2.3. адрес электронной почты (e-mail)</div>
            <div>3.2.4. место жительство Пользователя (при необходимости)</div>
            <div>3.2.5. фотографию (при необходимости)</div>
            <div>3.3. MCC защищает Данные, которые автоматически передаются при посещении страниц:</div>
            <div>- IP адрес;</div>
            <div>- информация из cookies;</div>
            <div>- информация о браузере</div>
            <div>- время доступа;</div>
            <div>- реферер (адрес предыдущей страницы).</div>
            <div>3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта, 
                требующим авторизации.</div>
            <div>3.3.2. MCC осуществляет сбор статистики об IP-адресах своих посетителей. Данная
                информация используется с целью предотвращения, выявления и решения 
                технических проблем.</div>
            <div>3.4. Любая иная персональная информация неоговоренная выше (история посещения,
                используемые браузеры, операционные системы и т.д.) подлежит надежному хранению
                и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. настоящей
                Политики конфиденциальности.</div>
            <h2>4. Цели сбора персональной информации пользователя</h2>    
            <div>4.1. Персональные данные Пользователя Администрация может использовать в целях:</div>
            <div>4.1.1. Идентификации Пользователя, зарегистрированного на сайте Mental Care Crew для
                его дальнейшей авторизации.</div>
            <div>4.1.2. Предоставления Пользователю доступа к персонализированным данным сайта 
                Mental Care Crew.</div>
            <div>4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, 
                запросов, касающихся использования сайта Mental Care Crew, обработки запросов и заявок 
                от Пользователя.</div>
            <div>4.1.4. Определения места нахождения Пользователя для обеспечения безопасности,
                предотвращения мошенничества.</div>
            <div>4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных 
                Пользователем.</div>
            <div>4.1.6. Создания учетной записи для использования частей сайта Mental Care Crew,
                если Пользователь дал согласие на создание учетной записи.</div>
            <div>4.1.7. Уведомления Пользователя по электронной почте.</div>
            <div>4.1.8. Предоставления Пользователю эффективной технической поддержки при возникновении
                проблем, связанных с использованием сайта Mental Care Crew.</div>
            <div>4.1.9. Предоставления Пользователю с его согласия специальных предложений, новостной
                рассылки и иных сведений от имени сайта Mental Care Crew.</div>
            <h2>5. Способы и сроки обработки персональной информации</h2>
            <div>5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока,
                любым законным способом, в том числе в информационных системах персональных данных с
                использованием средств автоматизации или без использования таких средств.</div>
            <div>5.2. Персональные данные Пользователя могут быть переданы уполномоченным органам
                государственной власти Российской Федерации только по основаниям и в порядке,
                установленным законодательством Российской Федерации.</div>
            <div>5.3. При утрате или разглашении персональных данных Администрация вправе не
                информировать Пользователя об утрате или разглашении персональных данных.</div>
            <div>5.4. Администрация принимает необходимые организационные и технические меры для 
                защиты персональной информации Пользователя от неправомерного или случайного 
                доступа, уничтожения, изменения, блокирования, копирования, распространения,
                а также от иных неправомерных действий третьих лиц.</div>
            <div>5.5. Администрация совместно с Пользователем принимает все необходимые меры 
                по предотвращению убытков или иных отрицательных последствий, вызванных утратой
                или разглашением персональных данных Пользователя.</div>
            <h2>6. Права и обязанности сторон</h2>
            <strong><div>6.1. Пользователь вправе:</div></strong>
            <div>6.1.1. Принимать свободное решение о предоставлении своих персональных данных, 
                необходимых для использования сайта Mental Care Crew, и давать согласие на
                их обработку.</div>
            <div>6.1.2. Обновить, дополнить предоставленную информацию о персональных данных
                в случае изменения данной информации.</div>
            <div>6.1.3. Пользователь имеет право на получение у Администрации информации, 
                касающейся обработки его персональных данных, если такое право не ограничено
                в соответствии с федеральными законами. Пользователь вправе требовать от
                Администрации уточнения его персональных данных, их блокирования или уничтожения
                в случае, если персональные данные являются неполными, устаревшими, неточными,
                незаконно полученными или не являются необходимыми для заявленной цели обработки,
                а также принимать предусмотренные законом меры по защите своих прав. Для
                этого достаточно уведомить Администрацию по указаному E-mail адресу.</div>
            <strong><div>6.2. Администрация обязана:</div></strong>
            <div>6.2.1. Использовать полученную информацию исключительно для целей,
                указанных в п. 4 настоящей Политики конфиденциальности.</div>
            <div>6.2.2. Обеспечить хранение конфиденциальной информации в тайне, 
                не разглашать без предварительного письменного разрешения Пользователя,
                а также не осуществлять продажу, обмен, опубликование, либо разглашение иными
                возможными способами переданных персональных данных Пользователя, за исключением 
                п.п. 5.2. настоящей Политики Конфиденциальности.</div>
            <div>6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных
                данных Пользователя согласно порядку, обычно используемого для защиты такого рода
                информации в существующем деловом обороте.</div>
            <div>6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему 
                Пользователю, с момента обращения или запроса Пользователя, или его законного
                представителя либо уполномоченного органа по защите прав субъектов персональных
                данных на период проверки, в случае выявления недостоверных персональных данных
                или неправомерных действий.</div>
            <h2>7. Ответственность сторон</h2>
            <div>7.1. Администрация, не исполнившая свои обязательства, несёт ответственность 
                за убытки, понесённые Пользователем в связи с неправомерным использованием
                персональных данных, в соответствии с законодательством Российской Федерации, 
                за исключением случаев, предусмотренных п.п. 5.2. и 7.2. настоящей Политики
                Конфиденциальности.</div>
            <div>7.2. В случае утраты или разглашения Конфиденциальной информации
                 Администрация не несёт ответственность, если данная конфиденциальная информация:</div>
            <div>7.2.1. Стала публичным достоянием до её утраты или разглашения.</div>
            <div>7.2.2. Была получена от третьей стороны до момента её получения Администрацией Ресурса.</div>
            <div>7.2.3. Была разглашена с согласия Пользователя.</div>
            <div>7.3. Пользователь несет полную ответственность за соблюдение требований законодательства 
                РФ, в том числе законов о рекламе, о защите авторских и смежных прав, об охране 
                товарных знаков и знаков обслуживания, но не ограничиваясь перечисленным, включая
                полную ответственность за содержание и форму материалов.</div>
            <div>7.4. Пользователь признает, что ответственность за любую информацию (в том числе,
                но не ограничиваясь: файлы с данными, тексты и т. д.), к которой он может иметь
                доступ как к части сайта Mental Care Crew, несет лицо, предоставившее такую
                информацию.</div>
            <div>7.5. Пользователь соглашается, что информация, предоставленная ему как часть сайта
                Mental Care Crew, может являться объектом интеллектуальной собственности, права на
                который защищены и принадлежат другим Пользователям, партнерам или рекламодателям,
                которые размещают такую информацию на сайте Mental Care Crew. Пользователь не вправе
                вносить изменения, передавать в аренду, передавать на условиях займа, продавать,
                распространять или создавать производные работы на основе такого Содержания 
                (полностью или в части), за исключением случаев, когда такие действия были письменно
                прямо разрешены собственниками такого Содержания в соответствии с условиями
                отдельного соглашения.</div>
            <div>7.6. В отношение текстовых материалов (статей, публикаций, находящихся в свободном
                публичном доступе на сайте Mental Care Crew) допускается их распространение 
                при условии, что будет дана ссылка на MCC.</div>
            <div>7.7. Администрация не несет ответственности перед Пользователем за любой убыток
                или ущерб, понесенный Пользователем в результате удаления, сбоя или невозможности
                сохранения какого-либо Содержания и иных коммуникационных данных, содержащихся 
                на сайте Mental Care Crew или передаваемых через него.</div>
            <div>7.8. Администрация не несет ответственности за любые прямые или косвенные убытки,
                произошедшие из-за: использования либо невозможности использования сайта,
                либо отдельных сервисов; несанкционированного доступа к коммуникациям Пользователя; 
                заявления или поведение любого третьего лица на сайте.</div>
            <div>7.9. Администрация не несет ответственность за какую-либо информацию, размещенную
                пользователем на сайте Mental Care Crew, включая, но не ограничиваясь: информацию, 
                защищенную авторским правом, без прямого согласия владельца авторского права.</div>
            <h2>8. Разрешение споров</h2>
            <div>8.1. До обращения в суд с иском по спорам, возникающим из отношений между 
                Пользователем и Администрацией, обязательным является предъявление претензии
                (письменного предложения или предложения в электронном виде о добровольном 
                урегулировании спора).</div>
            <div>8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, 
                письменно или в электронном виде уведомляет заявителя претензии о результатах рассмотрения
                претензии.</div>
            <div>8.3. При не достижении соглашения спор будет передан на рассмотрение 
                Арбитражного суда г. Novi Sad.</div>
            <div>8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем
                и Администрацией применяется действующее законодательство Российской Федерации.</div>
            <h2>9. Дополнительные условия</h2>
            <div>9.1. Администрация вправе вносить изменения в настоящую Политику конфиденциальности
                без согласия Пользователя.</div>
            <div>9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения
                на сайте Mental Care Crew, если иное не предусмотрено новой редакцией Политики
                конфиденциальности.</div>
            <div>9.3. Все предложения или вопросы касательно настоящей Политики конфиденциальности 
                следует сообщать по адресу: mentalcarecrew@gmail.com</div>
            <div>9.4. Действующая Политика конфиденциальности размещена на странице по
                 адресу https://mentalcarecrew.co/politic</div>
            <div>2022 г. г. Novi Sad, mentalcarecrew</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'politic-page',
    }
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1440px) and (min-width: 960px) {
.politic {
    font-family: Trebuchet MS;
    margin: 10px 15px;
}
.politic__btn {
    font-size: 40px;
    border: none;
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
}
.politic__page {
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: 2;
}
h1, h2 {
    text-align: center;
}
}
@media screen and (max-width: 960px) and (min-width: 640px) {
.politic {
    font-family: Trebuchet MS;
    margin: 10px 15px;
}
.politic__btn {
    font-size: 40px;
    border: none;
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
}
.politic__btn {
    font-size: 40px;
    border: none;
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
}
.politic__page {
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: 2;
}
h1, h2 {
    text-align: center;
}
}
@media screen and (max-width: 640px) and (min-width: 480px) {
.politic {
    font-family: Trebuchet MS;
    margin: 10px 15px;
}
.politic__btn {
    font-size: 30px;
    border: none;
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
}
.politic__page {
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: 2;
}
h1, h2 {
    text-align: center;
}
}
@media screen and (max-width: 480px) and (min-width: 320px) {
.politic {
    font-family: Trebuchet MS;
    margin: 10px 15px;
}
.politic__btn {
    font-size: 35px;
    border: none;
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
}
.politic__page {
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: 2;
}
h1, h2 {
    text-align: center;
}
h1 {
    font-size: 25px;
}
}
</style>
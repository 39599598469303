<template>
    <div class="form">
        <h1>Задать нам вопрос</h1>
        <div class="form__head">
            <div class="form__head-up">
              <h3>Чем мы можем помочь</h3>
              <div class="form__head-up-text">▪ Выслушаем вас и составим план дальнейших действий</div>
              <div class="form__head-up-text">▪ Подберём специалиста, если не знаете кого выбрать</div>
              <div class="form__head-up-text">▪ Запишем на приём и скоординируем ваше время и дату</div>
              <div class="form__head-up-text">▪ Поможем с оплатой приёма</div>
              <div class="form__head-up-text">▪ Ответим на вопросы о приёме</div>
              <div class="form__head-up-text">▪ А ещё сюда можно отправить свой отзыв</div>
            </div>
            <telegram-component
              :BotApi="BotApi"
              :chatID="chatID"
              :title="title"
              :sendButton="sendButton"
              :repeatButton="repeatButton"
              :sendFromPlaceholder="sendFromPlaceholder"
              :subjectPlaceholder="subjectPlaceholder"
              :messagePlaceholder="messagePlaceholder"
              :successNotification="successNotification"
            />
        </div>
    </div>
</template>

<script>
import TelegramComponent from './TelegramComponent.vue';

    export default {
        name: 'form-component',
        components: {
            TelegramComponent,
        },
        data() {
    return {
      BotApi: "5823480683:AAH5KTN5qlAkAoOmAqcToc5V5E43ujZi_J4",
      chatID: "-1001845889578",
      title: process.env.VUE_APP_FORMTITLE,
      sendButton: process.env.VUE_APP_SENDBUTTON,
      repeatButton: process.env.VUE_APP_REPEATBUTTON,
      sendFromPlaceholder: process.env.VUE_APP_SENDFORMPLACEHOLDER,
      subjectPlaceholder: process.env.VUE_APP_SUBJECTPLACEHOLDER,
      messagePlaceholder: process.env.VUE_APP_MESSAGEPLACEHOLDER,
      successNotification: process.env.VUE_APP_SUCCESSNITIFICATION,
    };
  },
    }
</script>

<style scoped>
@media screen and (max-width: 1920px) and (min-width: 1600px) {
    .form {
    font-family: Trebuchet MS;
    margin: 10px 15px;
}
h1 {
    margin: 10px 15px;
    font-size: 45px;
}
h3 {
    font-size: 35px;
}
.form__head {
    display: flex;
    justify-content: center;
}
.form__head-up {
    width: 500px;
    line-height: 2;
    text-align: left;
    min-height: 280px;
    padding: 20px;
    margin: 10px 20px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 1);
    border-radius: 10px;
}
.form__head-up-text {
    font-size: 30px;
}
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
.form {
    font-family: Trebuchet MS;
    margin: 10px 15px;
}
h1 {
    margin: 10px 15px;
    font-size: 45px;
}
h3 {
    font-size: 35px;
}
.form__head {
    display: flex;
    justify-content: center;
}
.form__head-up {
    width: 500px;
    line-height: 2;
    text-align: left;
    min-height: 280px;
    padding: 20px;
    margin: 10px 20px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 1);
    border-radius: 10px;
}
.form__head-up-text {
    font-size: 30px;
}
}
@media screen and (max-width: 1440px) and (min-width: 960px) {
.form {
    font-family: Trebuchet MS;
    margin: 10px 15px;
}
h1 {
    margin: 10px 15px;
}
.form__head {
    display: flex;
    justify-content: center;
}
.form__head-up {
    width: 300px;
    line-height: 2;
    text-align: left;
    min-height: 280px;
    padding: 20px;
    margin: 10px 20px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 1);
    border-radius: 10px;
}
.form__head-up-text {
    font-size: 18px;
}
}
@media screen and (max-width: 960px) and (min-width: 640px) {
.form {
    font-family: Trebuchet MS;
    margin: 10px 15px;
}
h1 {
    margin: 10px 15px;
}
.form__head {
    display: flex;
    justify-content: center;
}
.form__head-up {
    width: 300px;
    line-height: 2;
    text-align: left;
    min-height: 280px;
    padding: 20px;
    margin: 10px 20px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 1);
    border-radius: 10px;
}
.form__head-up-text {
    font-size: 16px;
}
}
@media screen and (max-width: 640px) and (min-width: 480px) {
.form {
    font-family: Trebuchet MS;
    margin: 10px 15px;
}
h1 {
    margin: 10px 15px;
}
.form__head {
    display: flex;
    flex-direction: column-reverse;
    margin-left: 80px;
}
.form__head-up {
    width: 300px;
    line-height: 2;
    text-align: left;
    min-height: 280px;
    padding: 20px;
    margin: 10px 20px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 1);
    border-radius: 10px;
}
.form__head-up-text {
    font-size: 16px;
}
}
@media screen and (max-width: 480px) and (min-width: 320px) {
.form {
    font-family: Trebuchet MS;
    margin: 10px 15px;
}
h1 {
    margin: 10px 15px;
}
.form__head {
    display: flex;
    flex-direction: column-reverse;
    margin-left: 20px;
}
.form__head-up {
    width: 300px;
    line-height: 2;
    text-align: left;
    min-height: 280px;
    padding: 20px;
    margin: 10px 20px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 1);
    border-radius: 10px;
}
.form__head-up-text {
    font-size: 16px;
}
}
</style>

<template>
    <div class="situation">
        <div class="situation__block">
            <form action="https://t.me/MCC_assistant" target="_blank">
                <InfoButton>я не знаю кого выбрать</InfoButton>
            </form>
            <div class="situation__block-text">
                <strong>Это нормально, если вы пока не знаете, какой формат и специалист
                вам подходит. Опишите свою ситуацию — мы свяжемся с вами и объясним
                дальнейшие шаги работы с Mental Care Crew</strong>
            </div>
        </div>
    </div>
</template>

<script>
import InfoButton from './UI/InfoButton.vue';

    export default {
        name: 'situation-component',
        components: {
            InfoButton
        }
    }
</script>

<style scoped>
@media screen and (max-width: 1920px) and (min-width: 1600px) {
    .situation {
        font-family: Trebuchet MS;
    }
    .situation__block {
        margin: 15px 50px;
        color: #FFEBCD;
    }
    .situation__block-text {
        font-size: 32px;
        padding: 10px 15px;
        color: grey;
    }
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
    .situation {
        font-family: Trebuchet MS;
    }
    .situation__block {
        margin: 15px 50px;
        color: #FFEBCD;
    }
    .situation__block-text {
        font-size: 28px;
        padding: 10px 15px;
        color: grey;
    }
}
@media screen and (max-width: 1440px) and (min-width: 1280px) {
    .situation {
        font-family: Trebuchet MS;
    }
    .situation__block {
        margin: 15px 50px;
        color: #FFEBCD;
    }
    .situation__block-text {
        font-size: 22px;
        padding: 10px 15px;
        color: grey;
    }
}
@media screen and (max-width: 1280px) and (min-width: 960px) {
    .situation {
        font-family: Trebuchet MS;
    }
    .situation__block {
        margin: 15px 50px;
        color: #FFEBCD;
    }
    .situation__block-text {
        font-size: 20px;
        padding: 10px 15px;
        color: grey;
    }
}
@media screen and (max-width: 960px) and (min-width: 640px) {
    .situation {
        font-family: Trebuchet MS;
    }
    .situation__block {
        margin: 15px 50px;
        color: #FFEBCD;
    }
    .situation__block-text {
        font-size: 20px;
        padding: 10px 15px;
        color: grey;
    }
}
@media screen and (max-width: 640px) and (min-width: 480px) {
    .situation {
        font-family: Trebuchet MS;
    }
    .situation__block {
        margin: 15px 50px;
        color: #FFEBCD;
    }
    .situation__block-text {
        font-size: 18px;
        padding: 10px 15px;
        color: grey;
    }
}
@media screen and (max-width: 480px) and (min-width: 320px) {
    .situation {
        font-family: Trebuchet MS;
    }
    .situation__block {
        margin: 15px 10px;
        color: #FFEBCD;
    }
    .situation__block-text {
        font-size: 17px;
        padding: 10px 15px;
        color: grey;
    }
}
</style>
<template>
    <div class="navbar">
        <div class="navbar__name">Mental Care Crew</div>
        <div class="navbar__btns">
            <menu-buttons @click="$router.push('/we')">о нас</menu-buttons>
            <menu-buttons @click="$router.push('/team')">команда</menu-buttons>
            <menu-buttons @click="$router.push('/')">главная</menu-buttons>
        </div>
    </div>
</template>

<script>
import MenuButtons from './UI/MenuButtons.vue';
    
export default {
    name: 'navbar-component',
    components: {
        MenuButtons,
    },
}
</script>

<style scoped>
@media screen and (max-width: 1920px) and (min-width: 1440px) {
.navbar {
    font-family: Trebuchet MS;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
}
.navbar__name {
    font-size: 50px;
    font-weight: bold;
    color: #5E9C51;
    text-align: left;
}
.navbar__btns {
    font-size: 27px;
 }
}
@media screen and (max-width: 1440px) and (min-width: 1280px) {
.navbar {
    font-family: Trebuchet MS;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
}
.navbar__name {
    font-size: 40px;
    font-weight: bold;
    color: #5E9C51;
    text-align: left;
}
.navbar__btns {
    font-size: 27px;
 }
}
@media screen and (max-width: 1280px) and (min-width: 960px) {
    .navbar {
    font-family: Trebuchet MS;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
}
.navbar__name {
    font-size: 30px;
    font-weight: bold;
    color: #5E9C51;
    text-align: left;
}
.navbar__btns {
    font-size: 27px;
 }
}
@media screen and (max-width: 960px) and (min-width: 640px) {
.navbar {
    font-family: Trebuchet MS;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
}
.navbar__name {
    font-size: 30px;
    font-weight: bold;
    color: #5E9C51;
    text-align: left;
}
.navbar__btns {
    font-size: 27px;
 }
}
@media screen and (max-width: 640px) and (min-width: 480px) {
.navbar {
    font-family: Trebuchet MS;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
}
.navbar__name {
    width: 50%;
    font-size: 26px;
    font-weight: bold;
    color: #5E9C51;
    text-align: left;
}
.navbar__btns {
    font-size: 25px;
    display: flex;
    justify-content: space-between;
}
}
@media screen and (max-width: 480px) and (min-width: 320px) {
    .navbar {
    font-family: Trebuchet MS;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
}
.navbar__name {
    width: 50%;
    font-size: 25px;
    font-weight: bold;
    color: #5E9C51;
    text-align: left;
}
.navbar__btns {
    font-size: 25px;
    display: flex;
    justify-content: space-between;
 }
}

</style>
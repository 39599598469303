<template>
    <button class="btn">
      <slot></slot>
    </button>
  </template>
  
  <script>
  export default {
    name: 'menu-buttons',
  }
  </script>
  
<style scoped>
@media screen and (max-width: 1920px) and (min-width: 1440px) {
  .btn {
      background-color: #ffffff;
      color: #5E9C51;
      cursor: pointer;
      font-size: 30px;
      border: none;
      padding: 5px 20px;
    }
    .btn:hover {
      color: black;
    }
}

@media screen and (max-width: 1440px) and (min-width: 1280px) {
    .btn {
      background-color: #ffffff;
      color: #5E9C51;
      cursor: pointer;
      font-size: 20px;
      border: none;
      padding: 5px 20px;
    }
    .btn:hover {
      color: black;
    }
}
@media screen and (max-width: 1280px) and (min-width: 960px) {
  .btn {
      background-color: #ffffff;
      color: #5E9C51;
      cursor: pointer;
      font-size: 50%;
      border: none;
      padding: 5px 20px;
    }
    .btn:hover {
      color: black;
    }
}
@media screen and (max-width: 960px) and (min-width: 640px) {
    .btn {
      background-color: #ffffff;
      color: #5E9C51;
      cursor: pointer;
      font-size: 50%;
      border: none;
      padding: 5px 20px;
    }
    .btn:hover {
      color: black;
    }
  }
@media screen and (max-width: 640px) and (min-width: 480px) {
    .btn {
      background-color: #ffffff;
      color: #5E9C51;
      cursor: pointer;
      font-size: 50%;
      border: none;
      padding: 5px 20px;
    }
    .btn:hover {
      color: black;
    }
  }
@media screen and (max-width: 480px) and (min-width: 320px) {
    .btn {
      background-color: #ffffff;
      color: #5E9C51;
      cursor: pointer;
      font-size: 13px;
      padding-left: 7px;
      border: none;
      padding: 5px 20px;
    }
    .btn:hover {
      color: black;
    }
  }
</style>
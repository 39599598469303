<template>
  <div class="footer">
    <div><strong>© 2022 MENTAL CARE CREW</strong></div>
    <div class="footer__say"><strong>Мы даем качественную помощь</strong></div>
    <button @click="$router.push('/politic')" class="footer__btn">Политика конфиденциальности</button>
  </div>
</template>

<script>
export default {
  name: 'footer-component',
}
</script>

<style scoped>
@media screen and (max-width: 1920px) and (min-width: 1600px) {
  .footer {
    background-color: #051B00;
    font-size: 35px;
    padding: 15px 15px;
    font-family: Trebuchet MS;
    color: white;
    text-align: left;
  }
  .footer__say {
    font-size: 33px;
  }
  .footer__btn {
    border: none;
    font-size: 33px;
    background-color: #051B00;
    color: white;
    cursor: pointer;
    font-family: Trebuchet MS;
  }
  .footer__btn:hover {
    opacity: 0.5;
  }
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
  .footer {
    background-color: #051B00;
    font-size: 35px;
    padding: 15px 15px;
    font-family: Trebuchet MS;
    color: white;
    text-align: left;
  }
  .footer__say {
    font-size: 33px;
  }
  .footer__btn {
    border: none;
    font-size: 33px;
    background-color: #051B00;
    color: white;
    cursor: pointer;
    font-family: Trebuchet MS;
  }
  .footer__btn:hover {
    opacity: 0.5;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1280px) {
  .footer {
    background-color: #051B00;
    font-size: 20px;
    padding: 15px 15px;
    font-family: Trebuchet MS;
    color: white;
    text-align: left;
  }
  .footer__say {
    font-size: 18px;
  }
  .footer__btn {
    border: none;
    font-size: 18px;
    background-color: #051B00;
    color: white;
    cursor: pointer;
    font-family: Trebuchet MS;
  }
  .footer__btn:hover {
    opacity: 0.5;
  }
}
@media screen and (max-width: 1280px) and (min-width: 960px) {
  .footer {
    background-color: #051B00;
    font-size: 22px;
    padding: 15px 15px;
    font-family: Trebuchet MS;
    color: white;
    text-align: left;
  }
  .footer__say {
    font-size: 20px;
  }
  .footer__btn {
    border: none;
    font-size: 20px;
    background-color: #051B00;
    color: white;
    cursor: pointer;
    font-family: Trebuchet MS;
  }
  .footer__btn:hover {
    opacity: 0.5;
  }
}
@media screen and (max-width: 960px) and (min-width: 640px) {
  .footer {
    background-color: #051B00;
    font-size: 22px;
    padding: 15px 15px;
    font-family: Trebuchet MS;
    color: white;
    text-align: left;
  }
  .footer__say {
    font-size: 20px;
  }
  .footer__btn {
    border: none;
    font-size: 20px;
    background-color: #051B00;
    color: white;
    cursor: pointer;
    font-family: Trebuchet MS;
  }
  .footer__btn:hover {
    opacity: 0.5;
  }
}
@media screen and (max-width: 640px) and (min-width: 480px) {
  .footer {
    background-color: #051B00;
    font-size: 22px;
    padding: 15px 15px;
    font-family: Trebuchet MS;
    color: white;
    text-align: left;
  }
  .footer__say {
    font-size: 19px;
  }
  .footer__btn {
    border: none;
    font-size: 19px;
    background-color: #051B00;
    color: white;
    cursor: pointer;
    font-family: Trebuchet MS;
  }
  .footer__btn:hover {
    opacity: 0.5;
  }
}
@media screen and (max-width: 480px) and (min-width: 320px) {
  .footer {
    background-color: #051B00;
    font-size: 22px;
    padding: 15px 15px;
    font-family: Trebuchet MS;
    color: white;
    text-align: left;
  }
  .footer__say {
    font-size: 17px;
    padding-top: 5px;
  }
  .footer__btn {
    border: none;
    font-size: 17px;
    background-color: #051B00;
    color: white;
    cursor: pointer;
    font-family: Trebuchet MS;
  }
  .footer__btn:hover {
    opacity: 0.5;
  }
}
</style>
<template>
    <form action="https://t.me/assistant_doctor_o" target="_blank">
        <button class="btn"><slot></slot></button>
    </form>
  </template>
  
  <script>
  export default {
    name: 'info-button',
  }
  </script>
  
<style scoped>
@media screen and (max-width: 1920px) and (min-width: 1600px) {
  .btn {
      width: 600px;
      height: 70px;
      background-color: #5E9C51;
      border: 1px solid #5E9C51;
      border-radius: 20px;
      color: #fff;
      cursor: pointer;
      font-size: 35px;
      padding: 5px 20px;
      margin: 15px 0;
    }
    .btn:hover {
      border: 1px solid black;
      background: #fff;
      color: black;
    }
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
  .btn {
      width: 500px;
      height: 60px;
      background-color: #5E9C51;
      border: 1px solid #5E9C51;
      border-radius: 20px;
      color: #fff;
      cursor: pointer;
      font-size: 30px;
      padding: 5px 20px;
      margin: 15px 0;
    }
    .btn:hover {
      border: 1px solid black;
      background: #fff;
      color: black;
    }
}
@media screen and (max-width: 1440px) and (min-width: 960px) {
    .btn {
      width: 500px;
      height: 45px;
      background-color: #5E9C51;
      border: 1px solid #5E9C51;
      border-radius: 20px;
      color: #fff;
      cursor: pointer;
      font-size: 25px;
      padding: 5px 20px;
      margin: 15px 0;
    }
    .btn:hover {
      border: 1px solid black;
      background: #fff;
      color: black;
    }
  }
@media screen and (max-width: 960px) and (min-width: 640px) {
    .btn {
      width: 300px;
      height: 45px;
      background-color: #5E9C51;
      border: 1px solid #5E9C51;
      border-radius: 20px;
      color: #fff;
      cursor: pointer;
      font-size: 18px;
      padding: 5px 20px;
      margin: 15px 0;
    }
    .btn:hover {
      border: 1px solid black;
      background: #fff;
      color: black;
    }
  }
@media screen and (max-width: 640px) and (min-width: 480px) {
    .btn {
      width: 250px;
      height: 55px;
      background-color: #5E9C51;
      border: 1px solid #5E9C51;
      border-radius: 20px;
      color: #fff;
      cursor: pointer;
      font-size: 16px;
      padding: 5px 20px;
      margin: 15px 0;
    }
    .btn:hover {
      border: 1px solid black;
      background: #fff;
      color: black;
    }
  }
@media screen and (max-width: 480px) and (min-width: 320px) {
    .btn {
      width: 250px;
      height: 45px;
      background-color: #5E9C51;
      border: 1px solid #5E9C51;
      border-radius: 20px;
      color: #fff;
      cursor: pointer;
      font-size: 17px;
      padding: 5px 10px;
      margin: 5px 0;
    }
    .btn:hover {
      border: 1px solid black;
      background: #fff;
      color: black;
    }
  }
  </style>
<template>
    <transition name="modal-fade">
      <div class="modal-backdrop">
        <div class="modal"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <div class="modal__header">
            <button
              type="button"
              class="modal__header-btn-close"
              @click="close"
              aria-label="Close modal"
            >
              x
            </button>
            <div class="modal__header-head"> 
              <p><img :src="image1" class="modal__header-img" /></p>
              <div class="modal__header-name">Мишелль Лесина</div>
            </div>
            <div class="modal__header-work">
              <h4>Основные запросы, на которых я специализируюсь:</h4>
              <div>▪ РПП, принятие себя и своего тела</div>
              <div>▪ Самореализация, деньги</div>
              <div>▪ Тревога (ГТР), депрессивные расстройства</div>
              <div>▪ Терапия пар</div>
              <h4>Я проходила обучение:</h4>
              <div>▪ Психолог-консультант (МИП)</div>
              <div>▪ Гештальт-терапевт (ИГТИП)</div>
              <div>▪ Групповые супервизии (80+ часов)</div>
              <div>▪ Личные супервизии (120+ часов)</div>
              <div>Работаю со взрослыми людьми — старше 18 лет</div>
              <h4>Сессии со мной длятся 60 минут, стоимость — 7500 rsd</h4>
            </div>
            <div style="display: flex;">
              <form action="https://instagram.com/choco_psy" target="_blank" class="modal__form">
                <button class="modal__footer"><img :src="image2" class="modal__footer-img" /></button>
              </form>
              <form action="https://t.me/+T3UQQi_00LMTo2f9" target="_blank" class="modal__form">
                <button class="modal__footer"><img :src="image3" class="modal__footer-img" /></button>
              </form>
            </div>
            <p><button
                type="button"
                class="btn-close"
                @click="close"
                aria-label="Close modal"
              >
                x
              </button></p>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  import image1 from "@/img/photo2.jpeg";
  import image2 from "@/img/insticon.png";
  import image3 from "@/img/telicon.png";
  
    export default {
      name: 'pop-up-component2',
      data () {
        return {
          image1: image1,
          image2: image2,
          image3: image3,
        };
      },
      methods: {
        close() {
          this.$emit('close');
        },
      },
    };
  </script>
  
  <style>
    
  </style>
<template>
    <div class="slider">
        <h1>Выберите специалиста <a style="color: #5E9C51">по душе</a></h1>
        <div class="slider__text">Получайте ответы на ваши вопросы, избавляйтесь от тревог и
            прорабатывайте важное со специалистами Mental Care Crew</div>
        <div class="slider__select">
            <select class="slider__select-form" v-model="sort">
                <option disabled value="">Методы работы</option>
                <option 
                    v-for="method in methods" 
                    v-bind:key="method.id" 
                    v-bind:value="method" 
                    v-text="method.title">
                </option>
            </select>
            <select class="slider__select-form" v-model="sort2">
                <option disabled value="">Виды рыботы</option>
                <option 
                    v-for="way in ways"
                    v-bind:key="way.id" 
                    v-bind:value="way" 
                    v-text="way.title">
                </option>
            </select>
            <select class="slider__select-form" v-model="sort3">
                <option disabled value="">Способы работы</option>
                <option 
                    v-for="place in places" 
                    v-bind:key="place.id" 
                    v-bind:value="place" 
                    v-text="place.title">
                </option>
            </select>
        </div>
        <swiper
            :slidesPerView="3.5"
            :spaceBetween="6"
            :modules="modules"
            class="swiper"
        >
            <swiper-slide
                class="swiper__btn"
                type="button"
                @click="showModal5"
            >
                <img :src="image5" class=""/>
                <div class="swiper__btn-btn">
                    <div class="swiper__btn-btn-name">Олег Ольшанский</div>
                    <div class="swiper__btn-btn-prof">врач-психиатр, психотерапевт</div>
                </div> 
            </swiper-slide>
            <PopUpComponent5
                v-show="isModalVisible5"
                @close="closeModal5"
            />
            <swiper-slide 
                class="swiper__btn"
                type="button"
                @click="showModal"
            >
                <img :src="image1" class=""/>
                <div class="swiper__btn-btn">
                    <div class="swiper__btn-btn-name">Маша Бурыка</div>
                    <div class="swiper__btn-btn-prof">клинический психолог, детский психолог, психодиагност</div>
                </div>
            </swiper-slide>
            <PopUpComponent
                v-show="isModalVisible"
                @close="closeModal"
            />
            <swiper-slide 
                class="swiper__btn"
                type="button"
                @click="showModal2"
            >
                <img :src="image2" class=""/>
                <div class="swiper__btn-btn">
                    <div class="swiper__btn-btn-name">Мишелль Лесина</div>
                   <div class="swiper__btn-btn-prof">психолог, гештальт-терапевт</div>
                </div>
            </swiper-slide>
            <PopUpComponent2
                v-show="isModalVisible2"
                @close="closeModal2"
            />
            <swiper-slide
                class="swiper__btn"
                type="button"
                @click="showModal4"
            >
                <img :src="image4" class=""/>
                <div class="swiper__btn-btn">
                    <div class="swiper__btn-btn-name">Мари Погодина</div>
                    <div class="swiper__btn-btn-prof">клинический психолог</div>
                </div> 
            </swiper-slide>
            <PopUpComponent4
                v-show="isModalVisible4"
                @close="closeModal4"
            />
            <swiper-slide 
                class="swiper__btn"
                type="button"
                @click="showModal6"
            >
                <img :src="image6" class=""/>
                <div class="swiper__btn-btn">
                    <div class="swiper__btn-btn-name">Мария Белякова</div>
                    <div class="swiper__btn-btn-prof">психолог-консультант</div>
                </div>
            </swiper-slide>
            <PopUpComponent6
                v-show="isModalVisible6"
                @close="closeModal6"
            />
        </swiper>
        <SituationComponent />
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import image1 from "@/img/photo1-1.jpg";
import image2 from "@/img/photo2-2.jpg";
import image4 from "@/img/photo4-4.jpg";
import image5 from "@/img/photo5-5.jpg";
import image6 from "@/img/photo6-6.jpg";
import PopUpComponent from './PopUpComponent.vue';
import PopUpComponent2 from './PopUpComponent2.vue';
import PopUpComponent4 from './PopUpComponent4.vue';
import PopUpComponent5 from './PopUpComponent5.vue';
import PopUpComponent6 from './PopUpComponent6.vue';
import SituationComponent from "./SituationComponent.vue";


export default {
    name: 'slider-component',
    components: {
        Swiper,
        SwiperSlide,
        PopUpComponent,
        PopUpComponent2,
        PopUpComponent4,
        PopUpComponent5,
        PopUpComponent6,
        SituationComponent,
    },
    data () {
      return {
        isModalVisible: false,
        isModalVisible2: false,
        isModalVisible4: false,
        isModalVisible5: false,
        isModalVisible6: false,
        image1: image1,
        image2: image2,
        image4: image4,
        image5: image5,
        image6: image6,
        methods: [
            {
                id: 1,
                title: 'КПТ',
                sort: 1,
            },
            {
                id: 2,
                title: 'Интегративный подход',
                sort: 2,
            },
            {
                id: 3,
                title: 'Психоанализ',
                sort: 3,
            },
            {
                id: 4,
                title: 'Фармакотерапия',
                sort: 4,
            },
            {
                id: 5,
                title: 'Составление реабилитационного плана',
                sort: 5,
            },
            {
                id: 6,
                title: 'Гештальт-терапия',
                sort: 6,
            },
            {
                id: 7,
                title: 'Патопсихологическая диагностика',
                sort: 7,
            },
        ],
        ways: [
            {
                id: 1,
                title: 'Индивидуальные сессии',
                sort2: 1,
            },
            {
                id: 2,
                title: 'Групповой формат',
                sort2: 2,
            },
            {
                id: 3,
                title: 'Супервизии',
                sort2: 3,
            },
        ],
        places: [
            {
                id: 1,
                title: 'Онлайн',
                sort3: 1,
            },
            {
                id: 2,
                title: 'Очно (Сербия)',
                sort3: 2,
            },
        ],
        sort: '',
        sort2: '',
        sort3: '',
      };
    },
    setup() {
        return {
            modules: [Pagination],
        };
    },
    methods: {
      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      },
      showModal2() {
        this.isModalVisible2 = true;
      },
      closeModal2() {
        this.isModalVisible2 = false;
      },
      showModal4() {
        this.isModalVisible4 = true;
      },
      closeModal4() {
        this.isModalVisible4 = false;
      },
      showModal5() {
        this.isModalVisible5 = true;
      },
      closeModal5() {
        this.isModalVisible5 = false;
      },
      showModal6() {
        this.isModalVisible6 = true;
      },
      closeModal6() {
        this.isModalVisible6 = false;
      },
    },
}
</script>

<style scoped>
@media screen and (max-width: 1920px) and (min-width: 1600px) {
    .slider {
    font-family: Trebuchet MS;
}
h1 {
    font-size: 40px;
    margin: 20px 0;
}
.slider__text {
    font-size: 30px;
    text-align: center;
    margin: 15px 200px;
}
.slider__select {
    display: flex;
    justify-content: space-around;
    margin: 10px 5px;
    
}
.slider__select-form {
    width: 300px;
    height: 60px;
    text-align: center;
    border: 1px solid #5E9C51;
    border-radius: 30px;
    color: #2F6B23;
    font-size: 25px;
    cursor: pointer;
}
.swiper {
  width: 100%;
  height: 100%;
  margin: 15px 5px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  z-index: 1;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 650px;
  border-radius: 25px;
  object-fit: cover;
  cursor: pointer;
}
.swiper-slide img:hover {
    opacity: 0.7;
}
.swiper__btn-btn {
    position: absolute;
    bottom: 20px;
    left: 5px;
    text-align: left;
    line-height: 1.5;
}
.swiper__btn-btn-name {
    color: #fff;
    font-size: 45px;
    font-weight: bold;
}
.swiper__btn-btn-prof {
    color: #fff;
    font-size: 35px;
}
.slider__phrase {
    font-size: 35px;
}
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
    .slider {
    font-family: Trebuchet MS;
}
h1 {
    font-size: 40px;
    margin: 20px 0;
}
.slider__text {
    font-size: 30px;
    text-align: center;
    margin: 15px 200px;
}
.slider__select {
    display: flex;
    justify-content: space-around;
    margin: 10px 5px;
    
}
.slider__select-form {
    width: 300px;
    height: 60px;
    text-align: center;
    border: 1px solid #5E9C51;
    border-radius: 30px;
    color: #2F6B23;
    font-size: 25px;
    cursor: pointer;
}
.swiper {
  width: 100%;
  height: 100%;
  margin: 15px 5px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  z-index: 1;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 600px;
  border-radius: 25px;
  object-fit: cover;
  cursor: pointer;
}
.swiper-slide img:hover {
    opacity: 0.7;
}
.swiper__btn-btn {
    position: absolute;
    bottom: 20px;
    left: 5px;
    text-align: left;
    line-height: 1.5;
}
.swiper__btn-btn-name {
    color: #fff;
    font-size: 40px;
    font-weight: bold;
}
.swiper__btn-btn-prof {
    color: #fff;
    font-size: 32px;
}
.slider__phrase {
    font-size: 35px;
}
}
@media screen and (max-width: 1440px) and (min-width: 1280px) {
.slider {
    font-family: Trebuchet MS;
}
h1 {
    font-size: 40px;
    margin: 20px 0;
}
.slider__text {
    font-size: 25px;
    text-align: center;
    margin: 15px 200px;
}
.slider__select {
    display: flex;
    justify-content: space-around;
    margin: 10px 5px;
    
}
.slider__select-form {
    width: 300px;
    height: 60px;
    text-align: center;
    border: 1px solid #5E9C51;
    border-radius: 30px;
    color: #2F6B23;
    font-size: 20px;
    cursor: pointer;
}
.swiper {
  width: 100%;
  height: 100%;
  margin: 15px 5px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  z-index: 1;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 450px;
  border-radius: 25px;
  object-fit: cover;
  cursor: pointer;
}
.swiper-slide img:hover {
    opacity: 0.7;
}
.swiper__btn-btn {
    position: absolute;
    bottom: 20px;
    left: 5px;
    text-align: left;
    line-height: 1.5;
}
.swiper__btn-btn-name {
    color: #fff;
    font-size: 32px;
    font-weight: bold;
}
.swiper__btn-btn-prof {
    color: #fff;
    font-size: 25px;
}
.slider__phrase {
    font-size: 35px;
}
}
@media screen and (max-width: 1280px) and (min-width: 960px) {
    .slider {
    font-family: Trebuchet MS;
}
h1 {
    font-size: 28px;
    margin: 20px 0;
}
.slider__text {
    font-size: 22px;
    text-align: center;
    margin: 15px 100px;
}
.slider__select {
    display: flex;
    justify-content: space-around;
    margin: 10px 5px;
}
.slider__select-form {
    width: 170px;
    height: 40px;
    text-align: center;
    border: 1px solid #5E9C51;
    border-radius: 30px;
    color: #2F6B23;
    font-size: 16px;
    cursor: pointer;
}
.swiper {
  width: 100%;
  height: 100%;
  margin: 15px 5px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  z-index: 1;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 350px;
  border-radius: 25px;
  object-fit: cover;
  cursor: pointer;
}
.swiper-slide img:hover {
    opacity: 0.7;
}
.swiper__btn-btn {
    position: absolute;
    bottom: 20px;
    left: 5px;
    text-align: left;
    line-height: 1.5;
}
.swiper__btn-btn-name {
    color: #fff;
    font-size: 23px;
    font-weight: bold;
}
.swiper__btn-btn-prof {
    color: #fff;
    font-size: 16px;
}
.slider__phrase {
    font-size: 25px;
}
}
@media screen and (max-width: 960px) and (min-width: 640px) {
.slider {
    font-family: Trebuchet MS;
}
h1 {
    font-size: 28px;
    margin: 20px 0;
}
.slider__text {
    font-size: 22px;
    text-align: center;
    margin: 15px 100px;
}
.slider__select {
    display: flex;
    justify-content: space-around;
    margin: 10px 5px;
}
.slider__select-form {
    width: 170px;
    height: 40px;
    text-align: center;
    border: 1px solid #5E9C51;
    border-radius: 30px;
    color: #2F6B23;
    font-size: 16px;
    cursor: pointer;
}
.swiper {
  width: 100%;
  height: 100%;
  margin: 15px 5px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  z-index: 1;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 350px;
  border-radius: 25px;
  object-fit: cover;
  cursor: pointer;
}
.swiper-slide img:hover {
    opacity: 0.7;
}
.swiper__btn-btn {
    position: absolute;
    bottom: 20px;
    left: 5px;
    text-align: left;
    line-height: 1.5;
}
.swiper__btn-btn-name {
    color: #fff;
    font-size: 23px;
    font-weight: bold;
}
.swiper__btn-btn-prof {
    color: #fff;
    font-size: 16px;
}
.slider__phrase {
    font-size: 25px;
}
}
@media screen and (max-width: 640px) and (min-width: 480px) {
.slider {
    font-family: Trebuchet MS;
}
h1 {
    font-size: 25px;
    margin: 20px 0;
}
.slider__text {
    font-size: 20px;
    text-align: center;
    margin: 15px 50px;
}
.slider__select {
    display: flex;
    justify-content: space-around;
    margin: 10px 5px;
}
.slider__select-form {
    width: 145px;
    height: 40px;
    text-align: center;
    border: 1px solid #5E9C51;
    border-radius: 30px;
    color: #2F6B23;
    font-size: 15px;
    cursor: pointer;
}
.swiper {
  width: 100%;
  height: 100%;
  margin: 15px 5px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  z-index: 1;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 300px;
  border-radius: 25px;
  object-fit: cover;
  cursor: pointer;
}
.swiper-slide img:hover {
    opacity: 0.7;
}
.swiper__btn-btn {
    position: absolute;
    bottom: 20px;
    left: 5px;
    text-align: left;
    line-height: 1.5;
}
.swiper__btn-btn-name {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}
.swiper__btn-btn-prof {
    color: #fff;
    font-size: 13px;
}
.slider__phrase {
    font-size: 25px;
}    
}
@media screen and (max-width: 480px) and (min-width: 320px) {
    .slider {
    font-family: Trebuchet MS;
}
h1 {
    font-size: 22px;
    margin: 20px 0;
}
.slider__text {
    font-size: 18px;
    text-align: center;
    margin: 15px 20px;
}
.slider__select {
    display: flex;
    justify-content: space-around;
    margin: 10px 5px;
}
.slider__select-form {
    width: 125px;
    height: 30px;
    text-align: center;
    border: 1px solid #5E9C51;
    border-radius: 30px;
    color: #2F6B23;
    font-size: 13px;
    cursor: pointer;
}
.swiper {
  width: 100%;
  height: 100%;
  margin: 15px 5px;
  background: #fff;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  z-index: 1;
  border-radius: 25px;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 250px;
  border-radius: 25px;
  object-fit: cover;
  cursor: pointer;
}
.swiper-slide img:hover {
    opacity: 0.7;
}
.swiper__btn-btn {
    position: absolute;
    bottom: 20px;
    left: 5px;
    text-align: left;
    line-height: 1.5;
}
.swiper__btn-btn-name {
    color: #fff;
    font-size: 17px;
    font-weight: bold;
}
.swiper__btn-btn-prof {
    color: #fff;
    font-size: 12px;
}
.slider__phrase {
    font-size: 20px;
}    
}
</style>
<template>
    <transition name="modal-fade">
      <div class="modal-backdrop">
        <div class="modal"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <div class="modal__header">
            <button
              type="button"
              class="modal__header-btn-close"
              @click="close"
              aria-label="Close modal"
            >
              x
            </button>
            <div class="modal__header-head"> 
              <p><img :src="image1" class="modal__header-img" /></p>
              <div class="modal__header-name">Олег Ольшанский</div>
            </div>
            <div class="modal__header-work">
              <h4>Я помогаю с вопросами детской психиатрии (0-18 лет):</h4>
              <div>▪ Консультации</div>
              <div>▪ Ведение при приеме медикаментозной терапии</div>
              <div>▪ Диагностика</div>
              <div>▪ Составление реабилитационного плана</div>
              <div>▪ Поведенческие проблемы, СДВГ</div>
              <h4>А также взрослой психиатрии и психотерапии:</h4>
              <div>▪ Депрессивный спектр расстройств</div>
              <div>▪ Тревожный спектр расстройств</div>
              <div>▪ Расстройства личности</div>
              <div>▪ Проблемы в отношениях</div>
              <div>▪ Нарушения адаптации</div>
              <h4>Я проходил обучение:</h4>
              <div>▪ Врач-педиатр (Санкт-Петербургский Государственный Педиатрический Медицинский университет)</div>
              <div>▪ Врач-психиатр (Санкт-Петербургский Государственный Педиатрический Медицинский университет)</div>
              <div>▪ Профессиональная переподготовка по специальности «Психотерапия» на базе Северо-Западного
                 Государственного Медицинского университета им. И. И. Мечникова</div>
              <h4>Получил основы обучения по психотерапии:</h4>
              <div>▪ Ассоциация КПТ (Санкт-Петербург)</div>
              <div>▪ Интегративный институт гештальт тренинга (Санкт-Петербург)</div>
              <div>▪ Ассоциация EMDR (Санкт-Петербург)</div>
              <h4>И обучения по психиатрии:</h4>
              <div>▪ ЦВЛ «Детская психиатрия»</div>
              <div>▪ Кафедра психиатрии и наркологии СПБГПМУ</div>
              <div>Работаю со взрослыми людьми старше 18 лет</div>
              <h4>Сессии со мной длятся 60-75 минут, стоимость — 7500 rsd</h4>
            </div>
            <div style="display: flex;">
              <form action="https://instagram.com/doctoro_o" target="_blank" class="modal__form">
                <button class="modal__footer"><img :src="image2" class="modal__footer-img" /></button>
              </form>
              <form action="https://t.me/psydoctorO_O" target="_blank" class="modal__form">
                <button class="modal__footer"><img :src="image3" class="modal__footer-img" /></button>
              </form>
            </div>
            <p><button
                type="button"
                class="btn-close"
                @click="close"
                aria-label="Close modal"
              >
                x
              </button></p>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  import image1 from "@/img/photo5.jpg";
  import image2 from "@/img/insticon.png";
  import image3 from "@/img/telicon.png";
  
    export default {
      name: 'pop-up-component5',
      data () {
        return {
          image1: image1,
          image2: image2,
          image3: image3,
        };
      },
      methods: {
        close() {
          this.$emit('close');
        },
      },
    };
  </script>
  
  <style>
    
  </style>
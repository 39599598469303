<template>
  <navbar-component></navbar-component>
  <div class="app">
    <router-view></router-view>
  </div>
  <footer-component></footer-component>
</template>

<script>
import FooterComponent from "@/components/FooterComponent.vue";
import NavbarComponent from "@/components/NavbarComponent.vue";

export default {
  name: 'App',
  components: {
    FooterComponent,
    NavbarComponent,
  }
}
</script>

<style>
@media screen and (max-width: 1920px) and (min-width: 1600px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .app {
    width: 100%;
    padding: 0;
    text-align: center;
    background-color: white;
  }
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .app {
    width: 100%;
    padding: 0;
    text-align: center;
    background-color: white;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1280px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .app {
    width: 100%;
    padding: 0;
    text-align: center;
    background-color: white;
  }
}
@media screen and (max-width: 1280px) and (min-width: 960px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .app {
    width: 100%;
    padding: 0;
    text-align: center;
    background-color: white;
  }
}
@media screen and (max-width: 960px) and (min-width: 640px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .app {
    width: 100%;
    padding: 0;
    text-align: center;
    background-color: white;
  }
}
@media screen and (max-width: 640px) and (min-width: 480px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .app {
    width: 100%;
    padding: 0;
    text-align: center;
    background-color: white;
  }
}
@media screen and (max-width: 480px) and (min-width: 320px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .app {
    width: 100%;
    padding: 0;
    text-align: center;
    background-color: white;
  }
}
</style>

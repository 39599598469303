<template>
    <transition name="modal-fade">
      <div class="modal-backdrop">
        <div class="modal"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <div class="modal__header">
            <button
              type="button"
              class="modal__header-btn-close"
              @click="close"
              aria-label="Close modal"
            >
              x
            </button>
            <div class="modal__header-head"> 
              <p><img :src="image1" class="modal__header-img" /></p>
              <div class="modal__header-name">Мари Погодина</div>
            </div>
            <div class="modal__header-work">
              <h4>Я помогаю с базовыми вопросами:</h4>
              <div>▪ Детско-родительскими отношениями</div>
              <div>▪ Выгоранием, эмоциональным и профессиональным</div>
              <div>▪ Личными финансами</div>
              <div>▪ Кризисом личности</div>
              <div>▪ Взаимоотношениями с людьми</div>
              <div>▪ Переживанием горя</div>
              <div>▪ Зависимыми отношениями</div>
              <div>▪ Избегающим поведением</div>
              <div>▪ Агрессией</div>
              <h4>И клиническими:</h4>
              <div>▪ Тревожно-депрессивный спектр (синдромы, фобии, ПА,
                 психосоматические расстройства, ГТР, сезонная депрессия и пр.)</div>
              <div>▪ РПП</div>
              <div>▪ ПРЛ, ДРЛ</div>
              <div>▪ Циклотимия, БАР</div>
              <div>▪ СДВГ</div>
              <div>▪ ПТСР</div>
              <div>▪ Послеродовая депрессия</div>
              <h4>Я проходила обучение:</h4>
              <div>▪ КПТ, 2021</div>
              <div>▪ Работа с ПТСР, 2020</div>
              <div>▪ Институт «ИНСАЙТ», клиническая психология</div>
              <div>▪ Институт «ИНСАЙТ», практическая психология</div>
              <div>▪ РГПУ им А.И. Герцена, факультет коррекционной педагогики, специальный
                 педагог–дефектолог</div>
              <div>Работаю со взрослыми и подростками старше 16 лет и придерживаюсь 
                долгосрочного формата, психокоррекции и психотерапии</div>
              <h4>Вы можете выбрать подходящие продолжительность и стоимость консультации:</h4>
              <h4>55 минут — 7500 rsd</h4>
            </div>
            <div style="display: flex;">
              <form action="https://instagram.com/alldaypsycho" target="_blank" class="modal__form">
                <button class="modal__footer"><img :src="image2" class="modal__footer-img" /></button>
              </form>
              <form action="https://t.me/alldaypsycho" target="_blank" class="modal__form">
                <button class="modal__footer"><img :src="image3" class="modal__footer-img" /></button>
              </form>
            </div>
            <p><button
                type="button"
                class="btn-close"
                @click="close"
                aria-label="Close modal"
              >
                x
              </button></p>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  import image1 from "@/img/photo4.jpg";
  import image2 from "@/img/insticon.png";
  import image3 from "@/img/telicon.png";
  
    export default {
      name: 'pop-up-component4',
      data () {
        return {
          image1: image1,
          image2: image2,
          image3: image3,
        };
      },
      methods: {
        close() {
          this.$emit('close');
        },
      },
    };
  </script>
  
  <style>
    
  </style>
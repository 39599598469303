<template>
    <div class="community">
        <div class="community__head">
            <h1>Главное — это <a style="color: #5E9C51">поддержка</a></h1>
            <h1>Присоединяйтесь к нашему коммьюнити</h1>
            <div class="community__head-text">Организуем безопасное пространство, где вы можете получить
            поддержку в трудную минуту и узнать больше о нашей работе</div>
        </div>
        <div class="comunity__body">
            <div class="community__body-left">
                <div class="community__body-left-block">
                    <div>
                        <div class="community__body-left-block-img">
                            <form action="https://t.me/mentalcarecrew" target="_blank">
                                <button style="border: none; cursor: pointer; background: #F1F1F1"><img :src="image2" class="community__body-left-block-img-is"/></button>
                            </form>
                        </div> 
                        <strong><div class="community__body-left-block-text">Telegram канал</div></strong>
                        <div class="community__body-left-block-text">Делимся новостями и интересными вещами</div>
                    </div>
                    <div>
                        <div class="community__body-left-block-img">
                            <img :src="image3" class="community__body-left-block-img-is"/>
                        </div>
                        <strong><div class="community__body-left-block-text">Наш психочатик</div></strong>
                        <div class="community__body-left-block-text">Общаемся, радуемся, грустим и сопереживаем</div>
                    </div>
                </div>
                <div class="community__body-left-block">
                    <div>
                        <div class="community__body-left-block-img" @click="$router.push('/form')">
                            <img :src="image4" class="community__body-left-block-img-is"/>
                        </div>
                        <strong><div class="community__body-left-block-text">Задать вопрос</div></strong>
                        <div class="community__body-left-block-text">Помогаем выбрать специалиста</div>
                    </div>
                    <div>
                        <div class="community__body-left-block-img">
                            <img :src="image5" class="community__body-left-block-img-is"/>
                        </div>
                        <strong><div class="community__body-left-block-text">Дальше - больше</div></strong>
                        <div class="community__body-left-block-text">Следите за новостями, у нас много планов :)</div>
                    </div>
                </div>
            </div>
            <div class="community__body-rigth">
                <img :src="image1" class="community__body-rigth-img" />
            </div>
        </div>
    </div>
</template>

<script>
import image1 from "@/img/Footpic.jpg";
import image2 from "@/img/dog.png";
import image3 from "@/img/oblako.png";
import image4 from "@/img/envelope.png";
import image5 from "@/img/tsss.png";


    export default {
        name: 'community-component',
        data () {
            return {
                image1: image1,
                image2: image2,
                image3: image3,
                image4: image4,
                image5: image5,
            }
        }
    }
</script>

<style scoped>
@media screen and (max-width: 1920px) and (min-width: 1600px) {
.community {
    font-family: 'Trebuchet MS';
    margin: 10px 15px;
}
h1 {
    font-size: 40px;
    margin: 20px 0;
}
.community__head-text {
    font-size: 30px;
    text-align: center;
    margin: 15px 200px;
}
.comunity__body {
    display: flex;
    justify-content: space-around;
    background: #FBFBFB;
}
.community__body-left {
    display: flex;
    flex-direction: column;
}
.community__body-left-block {
    display: flex;
    justify-content: space-between;
}
.community__body-left-block-text {
    font-size: 27px;
    text-align: left;
    width: 60%;
    padding-left: 30px;
}
.community__body-left-block-img {
    width: 400px;
    height: 300px;
    background: #F1F1F1;
    border-radius: 25px;
    vertical-align: middle;
    margin: 10px 20px;
}
.community__body-left-block-img:hover {
    opacity: 0.7;
    cursor: pointer;
}
.community__body-left-block-img-is {
    height: 200px;
    padding-top: 100px;
}
.community__body-left-block-img-is:hover {
    height: 220px;
}
.community__body-rigth {
    margin: 5px 10px;
}
.community__body-rigth-img {
    height: 900px;
}
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
.community {
    font-family: 'Trebuchet MS';
    margin: 10px 15px;
}
h1 {
    font-size: 40px;
    margin: 20px 0;
}
.community__head-text {
    font-size: 24px;
    text-align: center;
    margin: 15px 200px;
}
.comunity__body {
    display: flex;
    justify-content: space-around;
    background: #FBFBFB;
}
.community__body-left {
    display: flex;
    flex-direction: column;
}
.community__body-left-block {
    display: flex;
    justify-content: space-between;
}
.community__body-left-block-text {
    font-size: 28px;
    text-align: left;
    width: 67%;
    padding-left: 30px;
}
.community__body-left-block-img {
    width: 350px;
    height: 250px;
    background: #F1F1F1;
    border-radius: 25px;
    vertical-align: middle;
    margin: 10px 20px;
}
.community__body-left-block-img:hover {
    opacity: 0.7;
    cursor: pointer;
}
.community__body-left-block-img-is {
    height: 175px;
    padding-top: 70px;
}
.community__body-left-block-img-is:hover {
    height: 185px;
}
.community__body-rigth {
    margin: 5px 10px;
}
.community__body-rigth-img {
    height: 750px;
}
}
@media screen and (max-width: 1440px) and (min-width: 1280px) {
.community {
    font-family: 'Trebuchet MS';
    margin: 10px 15px;
}
h1 {
    font-size: 40px;
    margin: 20px 0;
}
.community__head-text {
    font-size: 24px;
    text-align: center;
    margin: 15px 200px;
}
.comunity__body {
    display: flex;
    justify-content: space-around;
    background: #FBFBFB;
}
.community__body-left {
    display: flex;
    flex-direction: column;
}
.community__body-left-block {
    display: flex;
    justify-content: space-between;
}
.community__body-left-block-text {
    font-size: 18px;
    text-align: left;
    width: 60%;
    padding-left: 30px;
}
.community__body-left-block-img {
    width: 300px;
    height: 180px;
    background: #F1F1F1;
    border-radius: 25px;
    vertical-align: middle;
    margin: 10px 20px;
}
.community__body-left-block-img:hover {
    opacity: 0.7;
    cursor: pointer;
}
.community__body-left-block-img-is {
    height: 120px;
    padding-top: 70px;
}
.community__body-left-block-img-is:hover {
    height: 145px;
}
.community__body-rigth {
    margin: 5px 10px;
}
.community__body-rigth-img {
    height: 570px;
}
}
@media screen and (max-width: 1280px) and (min-width: 960px) {
    .community {
    font-family: 'Trebuchet MS';
    margin: 10px 15px;
}
h1 {
    font-size: 28px;
    margin: 20px 0;
}
.community__head-text {
    font-size: 22px;
    text-align: center;
    margin: 15px 70px;
}
.comunity__body {
    display: flex;
    justify-content: space-around;
    background: #FBFBFB;
}
.community__body-left {
    display: flex;
    flex-direction: column;
}
.community__body-left-block {
    display: flex;
}
.community__body-left-block-text {
    font-size: 15px;
    text-align: left;
    width: 90%;
    padding-left: 30px;
}
.community__body-left-block-img {
    width: 210px;
    height: 150px;
    background: #F1F1F1;
    border-radius: 25px;
    vertical-align: middle;
    margin: 10px 20px;
}
.community__body-left-block-img:hover {
    opacity: 0.7;
    cursor: pointer;
}
.community__body-left-block-img-is {
    height: 100px;
    padding-top: 50px;
}
.community__body-left-block-img-is:hover {
    height: 120px;
}
.community__body-rigth {
    margin: 5px 10px;
}
.community__body-rigth-img {
    height: 430px;
}
}
@media screen and (max-width: 960px) and (min-width: 750px) {
.community {
    font-family: 'Trebuchet MS';
    margin: 10px 15px;
}
h1 {
    font-size: 28px;
    margin: 20px 0;
}
.community__head-text {
    font-size: 22px;
    text-align: center;
    margin: 15px 70px;
}
.comunity__body {
    display: flex;
    justify-content: space-around;
    background: #FBFBFB;
}
.community__body-left {
    display: flex;
    flex-direction: column;
}
.community__body-left-block {
    display: flex;
}
.community__body-left-block-text {
    font-size: 15px;
    text-align: left;
    width: 90%;
    padding-left: 30px;
}
.community__body-left-block-img {
    width: 210px;
    height: 150px;
    background: #F1F1F1;
    border-radius: 25px;
    vertical-align: middle;
    margin: 10px 20px;
}
.community__body-left-block-img:hover {
    opacity: 0.7;
    cursor: pointer;
}
.community__body-left-block-img-is {
    height: 100px;
    padding-top: 50px;
}
.community__body-left-block-img-is:hover {
    height: 120px;
}
.community__body-rigth {
    margin: 5px 10px;
}
.community__body-rigth-img {
    height: 430px;
}
}
@media screen and (max-width: 750px) and (min-width: 640px) {
.community {
    font-family: 'Trebuchet MS';
    margin: 10px 15px;
}
h1 {
    font-size: 28px;
    margin: 20px 0;
}
.community__head-text {
    font-size: 22px;
    text-align: center;
    margin: 15px 70px;
}
.comunity__body {
    display: flex;
    justify-content: space-around;
    background: #FBFBFB;
}
.community__body-left {
    display: flex;
    flex-direction: column;
}
.community__body-left-block {
    display: flex;
}
.community__body-left-block-text {
    font-size: 14px;
    text-align: left;
    width: 80%;
    padding-left: 30px;
}
.community__body-left-block-img {
    width: 170px;
    height: 120px;
    background: #F1F1F1;
    border-radius: 25px;
    vertical-align: middle;
    margin: 10px 20px;
}
.community__body-left-block-img:hover {
    opacity: 0.7;
    cursor: pointer;
}
.community__body-left-block-img-is {
    height: 80px;
    padding-top: 40px;
}
.community__body-left-block-img-is:hover {
    height: 100px;
}
.community__body-rigth {
    margin: 5px 10px;
}
.community__body-rigth-img {
    height: 380px;
}
}
@media screen and (max-width: 640px) and (min-width: 480px) {
.community {
    font-family: 'Trebuchet MS';
    margin: 10px 15px;
}
h1 {
    font-size: 25px;
    margin: 20px 0;
}
.community__head-text {
    font-size: 20px;
    text-align: center;
    margin: 15px 70px;
}
.comunity__body {
    display: flex;
    flex-direction: column-reverse;
    background: #FBFBFB;
}
.community__body-left {
    display: flex;
    flex-direction: column;
}
.community__body-left-block {
    display: flex;
    justify-content: space-around;
}
.community__body-left-block-text {
    font-size: 15px;
    text-align: left;
    width: 90%;
    padding-left: 30px;
}
.community__body-left-block-img {
    width: 190px;
    height: 130px;
    background: #F1F1F1;
    border-radius: 25px;
    vertical-align: middle;
    margin: 10px 20px;
}
.community__body-left-block-img:hover {
    opacity: 0.7;
    cursor: pointer;
}
.community__body-left-block-img-is {
    height: 90px;
    padding-top: 50px;
}
.community__body-left-block-img-is:hover {
    height: 110px;
}
.community__body-rigth {
    margin: 5px 10px;
}
.community__body-rigth-img {
    height: 400px;
}
}
@media screen and (max-width: 480px) and (min-width: 320px) {
.community {
    font-family: 'Trebuchet MS';
    margin: 10px 15px;
}
h1 {
    font-size: 22px;
    margin: 20px 0;
}
.community__head-text {
    font-size: 18px;
    text-align: center;
    margin: 15px 20px;
}
.comunity__body {
    display: flex;
    flex-direction: column-reverse;
    background: #FBFBFB;
}
.community__body-left {
    display: flex;
    flex-direction: column;
}
.community__body-left-block {
    display: flex;
    justify-content: space-around;
}
.community__body-left-block-text {
    font-size: 14px;
    text-align: left;
    width: 90%;
    padding-left: 30px;
}
.community__body-left-block-img {
    width: 160px;
    height: 100px;
    background: #F1F1F1;
    border-radius: 25px;
    vertical-align: middle;
    margin: 10px 20px;
}
.community__body-left-block-img:hover {
    opacity: 0.7;
    cursor: pointer;
}
.community__body-left-block-img-is {
    height: 60px;
    padding-top: 30px;
}
.community__body-left-block-img-is:hover {
    height: 80px;
}
.community__body-rigth {
    margin: 5px 10px;
}
.community__body-rigth-img {
    height: 350px;
}
}

</style>
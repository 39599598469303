import MainButton from "@/components/UI/MainButton";
import UpButton from "@/components/UI/UpButton";
import UpButton2 from "@/components/UI/UpButton2";
import MenuButtons from "@/components/UI/MenuButtons";
import InfoButton from "@/components/UI/InfoButton";

export default [
    MainButton,
    UpButton,
    UpButton2,
    MenuButtons,
    InfoButton,
]